import React from 'react';
import styled from 'styled-components';
import { Text } from '@components/ui';
import {
  FLIGHT_TYPES,
  FlightType,
} from '@components/booking/FlightDetail/types';
import { getFlightTypeTitle } from '@modules/main/utilities';

const Wrapper = styled.div<{ background: string }>`
  background: ${({ background }) => background};
  border-radius: 8px;
  padding: 4px 8px;
  cursor: pointer;

  color: #000000;
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  margin-left: 5px;

  & > svg {
    margin-right: 3px;
  }

  @media (max-width: 767px) {
    margin-left: 0;
    padding: 5px 6px;
    width: 95px;
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
  }
`;

const TippyDescText = styled(Text)`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
`;

const TippyDescriptionBlock = styled.div`
  & > * {
    margin-bottom: 7px;
    display: block;
  }

  & > :first-child {
    margin-bottom: 10px;
  }

  & > :last-child {
    margin-bottom: 0;
  }
`;

export default function ({
  flightType,
  className,
}: {
  flightType?: FlightType;
  className?: string;
}) {
  const title = flightType ? getFlightTypeTitle(flightType, true) : '';

  let descriptionBlock = null as any;

  switch (flightType) {
    case FLIGHT_TYPES.CHARTER: {
      descriptionBlock = (
        <TippyDescriptionBlock>
          <TippyDescText>
            Нерегулярные рейсы обычных авиакомпаний, организованные
            туроператорами или другими заказчиками.
          </TippyDescText>
          <TippyDescText>
            - время вылета/прилета может поменяться;
          </TippyDescText>
          <TippyDescText>
            - выписка билета может быть дольше обычного;
          </TippyDescText>
          <TippyDescText>- вылет может задержаться;</TippyDescText>
          <TippyDescText>- билеты нельзя вернуть.</TippyDescText>
        </TippyDescriptionBlock>
      );
      break;
    }
    default: {
      descriptionBlock = (
        <TippyDescriptionBlock>
          <TippyDescText>
            Постоянное расписание, строгие обязательства, бонусные программы и
            доп. услуги.
          </TippyDescText>
          <TippyDescText>
            - выполняются крупными авиакомпаниями строго по расписанию;
          </TippyDescText>
          <TippyDescText>
            - билеты можно вернуть, если поменялись планы;
          </TippyDescText>
          <TippyDescText>
            - могут действовать скидки и бонусные программы.
          </TippyDescText>
        </TippyDescriptionBlock>
      );
      break;
    }
  }

  return (
    <>
      {title && (
        <Wrapper
          className={className}
          background={
            flightType === FLIGHT_TYPES.REGULAR ||
            flightType === FLIGHT_TYPES.LOWCOSTER
              ? '#E3EAFF'
              : '#FFF3CD'
          }
        >
          {/* <PlaneSvg /> */}
          {title && <span>{title}</span>}
        </Wrapper>
      )}
    </>
  );
}
