import {
  OrderPosition,
  GetOrderByNumberDto,
  GetCustomerOrdersDto,
  GetAvailableInsurancesDto,
  PriceDetalizationItem,
  RisksEntity,
  PriceDetalization,
} from './types';
import _ from 'lodash';
import { Helper } from '@utils';
import insuranceCancelSrc from '@assets/media/insurances/insurance1.svg';
import insuranceProtectionSrc from '@assets/media/insurances/insurance2.svg';
import insuranceCovidSrc from '@assets/media/insurances/insurance3.svg';
import insuranceDelaySrc from '@assets/media/insurances/insurance4.svg';
import insuranceWeatherSrc from '@assets/media/insurances/insurance5.svg';
import insuranceDoctorSrc from '@assets/media/insurances/insurance6.svg';
import insurance7 from '@assets/media/insurances/insurance7.svg';

import alphastrahPath from '@assets/media/insurances/alphastrah.png';
import insurionPath from '@assets/media/insurances/insurion.png';
import Arrow from '@assets/media/insurances/arrow.svg';
import { trainOrderPositionToAviaPositionAdapter } from '@modules/trainOrders/utils';
import { BusForRouteDetail } from '@components/bus/Booking/components/TripDetails/components/RouteDetail';
import { formatMinutes } from '@components/bus/Booking/controller';

function normalizePassengers(args: OrderPosition) {
  const getSegmentNameFromToById = (segmentId: any) => {
    const segment1 = segmentId.map((str: string) => {
      const segment = args.segmentGroups
        .map((seg: any) => seg.segments)
        .flat()

        .flat()
        .find((x: any) => x.id === str);
      return (
        segment?.departureCity?.name + ' - ' + segment?.arrivalCity?.name + ' '
      );
    });

    return segment1;
  };

  return {
    ...args,
    passengers: args.passengers.map((passenger) => {
      const luggageList = args.luggages
        ? args.luggages.find(
            (value) => value.passengerId === passenger.passengerId,
          ) ||
          ({ passengerId: '', items: [] } as (typeof args.luggages)[number])
        : ({ passengerId: '', items: [] } as (typeof args.luggages)[number]);

      luggageList.items.map(
        (item) =>
          item?.aviaSegmentIds &&
          (item['segmentFromTo'] = getSegmentNameFromToById(
            item.aviaSegmentIds,
          )),
      );

      luggageList.items.map(
        (item) =>
          item?.aviaSegmentIds &&
          (item['passangerName'] =
            args.passengers.find(
              (pas) => item.aviaPassengerId === pas.passengerId,
            )?.firstName +
            ' ' +
            args.passengers.find(
              (pas) => item.aviaPassengerId === pas.passengerId,
            )?.lastName),
      );

      const mealList = args.meals
        ? args.meals.find(
            (value) => value.passengerId === passenger.passengerId,
          ) || ({ passengerId: '', items: [] } as (typeof args.meals)[number])
        : ({ passengerId: '', items: [] } as (typeof args.meals)[number]);

      mealList.items.map(
        (item) =>
          item?.aviaSegmentIds &&
          (item['segmentFromTo'] = getSegmentNameFromToById(
            item.aviaSegmentIds,
          )),
      );

      mealList.items.map(
        (item) =>
          item?.aviaSegmentIds &&
          (item['passangerName'] =
            args.passengers.find(
              (pas) => item.aviaPassengerId === pas.passengerId,
            )?.firstName +
            ' ' +
            args.passengers.find(
              (pas) => item.aviaPassengerId === pas.passengerId,
            )?.lastName),
      );

      const seatsListTemp = (args.seatsAssignment || []).find(
        (value) => value.passengerId === passenger.passengerId,
      );

      const seatsList = {
        ...seatsListTemp,
        items: seatsListTemp?.items?.map((x) => {
          const seatNumberArr = x.name.split(' ');
          return {
            ...x,
            seatNumber: x.seatNumber || seatNumberArr[seatNumberArr.length - 1],
            segmentFromTo: getSegmentNameFromToById(x.aviaSegmentIds),
            passangerName:
              args.passengers.find(
                (pas) => x.aviaPassengerId === pas.passengerId,
              )?.firstName +
              ' ' +
              args.passengers.find(
                (pas) => x.aviaPassengerId === pas.passengerId,
              )?.lastName,
          };
        }),
      };

      const otherAncillaryServices = args.otherAncillaryServices
        ? args.otherAncillaryServices.find(
            (value) => value.passengerId === passenger.passengerId,
          ) || ({ passengerId: '', items: [] } as (typeof args.meals)[number])
        : ({ passengerId: '', items: [] } as (typeof args.meals)[number]);

      return {
        ...passenger,
        luggage: {
          ...luggageList,
        },
        seatsList,
        meals: {
          ...mealList,
        },
        otherAncillaryServices: {
          ...otherAncillaryServices,
        },
      };
    }),
  };
}

function formatPriceDetalizationItem(args: PriceDetalizationItem) {
  const typeMap: [string, string, string][] = [
    ['взрослый', 'взрослых', 'взрослых'],
    ['ребёнок', 'ребёнка', 'детей'],
    ['младенец', 'младенца', 'детей'],
  ];
  let name = args.description;
  const detalization = args.detalization;
  const type = '';
  // if (args.priceType === 3) {
  //   name = `${args.quantity} ${Helper.pluralWord(
  //     args.quantity || 0,
  //     ...typeMap[0]
  //   )}`;
  // }
  if (args.priceType === 4) {
    name = `${args.quantity} ${Helper.pluralWord(
      args.quantity || 0,
      ...typeMap[1],
    )}`;
  }
  if (args.priceType === 5) {
    name = `${args.quantity} ${Helper.pluralWord(
      args.quantity || 0,
      ...typeMap[2],
    )}`;
  }
  return {
    quantity: 0,
    price: args.value,
    name,
    type,
    detalization,
    priceType: args.priceType,
  };
}

function createPriceArrayForTotalDetalization(args: PriceDetalizationItem) {
  return {
    total: args.value,
    agentTotal: args.detalization.find((x) => x.priceType === 16)?.value,
    positionList: args.detalization
      .filter((x) => x.priceType !== 16)
      .map((item) => ({
        positionName: item.description,
        isBold: item.priceType === 17,
        total: item.value,
        priceType: item.priceType,
        detalizationList: item.detalization.map(formatPriceDetalizationItem),
      })),
  };
}

function createPriceArrayForPosition(
  state: ReturnType<typeof normalizePassengers>,
) {
  const priceArray = _(state?.orderPositionAmount?.detalization)
    .map(formatPriceDetalizationItem)
    .value();

  return {
    ...state,
    priceDetail: {
      amount: state.orderPositionAmount?.value,
      detailList: priceArray,
    },
  };
}

export const isActive = (value: { isActive: boolean }) => value.isActive;

/**
 *  Filter and modify order positions
 */
export function getOrderByNumberNormalizer(args: GetOrderByNumberDto) {
  const isTrainOrder = args.railwayOrderPositions?.length > 0;
  const isBusOrder = !!(args.busOrderPositions?.length || 0 > 0);

  let orderPositions: OrderPosition[] = [];
  if (isTrainOrder) {
    if (args.railwayOrderPositions.length > 0) {
      args.railwayOrderPositions.forEach((x) => {
        orderPositions.push(
          trainOrderPositionToAviaPositionAdapter(
            x.orderPosition,
            isTrainOrder,
            isBusOrder,
          ),
        );
      });
    }
  } else if (isBusOrder) {
    if (args.busOrderPositions?.length || 0 > 0) {
      args.busOrderPositions?.forEach((x) => {
        orderPositions.push(
          trainOrderPositionToAviaPositionAdapter(
            x.orderPosition,
            isTrainOrder,
            isBusOrder,
          ),
        );
      });
    }
  } else {
    orderPositions = args.orderPositions;
  }

  const normalizedOrderPositions = _(orderPositions)
    .map(_.flow(normalizePassengers, createPriceArrayForPosition))
    .value();

  // TODO: MAP DETALIZATION
  const orderAmountFake: PriceDetalization = {
    description: '',
    priceType: 0,
    quantity: 0,
    value: 0,
    detalization: [],
  };
  return {
    ...args,
    isBusOrder: isBusOrder,
    isTrainOrder: isTrainOrder,
    totalDetalization: createPriceArrayForTotalDetalization(
      isTrainOrder ? orderAmountFake : args.orderAmount,
    ),
    orderPositions: normalizedOrderPositions,
    annulatedAviaOrderPositions: _(args.annulatedAviaOrderPositions)
      .map(_.flow(normalizePassengers, createPriceArrayForPosition))
      .value(),
  };
}

/**
 * Filtering order positions
 */
export function getOrdersListNormalizer(args: GetCustomerOrdersDto) {
  return {
    ...args,
    orders: args.orders || [],
  };
}

function getInsuranceIcon(productType: string) {
  switch (productType) {
    case 'ANTICOVID':
      return insuranceCovidSrc;
    case 'Защита на время полета':
      return insuranceProtectionSrc;
    case 'Отмена поездки':
      return insuranceCancelSrc;
    case 'Страховка от задержки рейса':
    case 'Задержка рейса':
      return insuranceDelaySrc;
    case 'Гарантия хорошей погоды для туристов':
    case 'Гарантия хорошей погоды':
      return insuranceWeatherSrc;
    case 'Online-врач в поездку':
      return insuranceDoctorSrc;
    case 'Медицинские расходы':
      return insuranceDoctorSrc;
    case 'Полёт под защитой':
      return insurance7;
  }
}

function getInsuranceDescription(productType: string) {
  switch (productType) {
    case 'ANTICOVID':
      return 'Нет ничего важнее Вашего здоровья. Получите выплату при заболевании в поездке.';
    case 'Защита на время полета':
      return 'Получите выплату на карту при задержке рейса, потере багажа и других страховых случаях.';
    case 'Отмена поездки':
      return 'Компенсация до 5 000 ₽ при отмене или изменении сроков перелёта по одной из причин:';
    case 'Online-врач в поездку':
      return 'Дистанционная квалифицированная медицинская помощь терапевта или педиатра через приложение на всё время поездки в любой точке мира.';
    case 'Страховка от задержки рейса':
    case 'Задержка рейса':
      return 'Моментальная выплата от 3000 ₽ на карту, в случае задержки рейса от 3ч.';
    case 'Гарантия хорошей погоды для туристов':
    case 'Гарантия хорошей погоды':
      return 'Моментальная выплата 5000 ₽ на карту, в случае плохой погоды во время отдыха.';
    case 'Полёт под защитой':
      return 'Моментальная выплата на карту при задержке рейса, компенсация при неприятностях с багажом и не только';
  }
}

function getCompany(productType: string) {
  switch (productType) {
    case 'Задержка рейса':
    case 'Гарантия хорошей погоды':
    case 'Гарантия хорошей погоды для туристов':
    case 'Страховка от задержки рейса':
      return { name: 'Insurion', logo: insurionPath };
    default:
      return { name: 'АльфаСтрахование', logo: alphastrahPath };
  }
}

function getLabel(checked: boolean) {
  if (checked) {
    return { color: '#C9DEFF', text: 'Ждет оплаты', icon: Arrow };
  }
  return null;
}

function formatRisks(productType: string, risks: RisksEntity[]): RisksEntity[] {
  switch (productType) {
    case 'ANTICOVID':
      return [
        {
          id: '',

          coverage: { value: 15000, currency: 'RUB' },
          description: 'в случае диагностики COVID-19',
        },
        {
          id: '',
          coverage: { value: 30000, currency: 'RUB' },
          description: 'в случае госпитализации с COVID-19',
        },
      ];
    case 'Защита на время полета':
      return risks.map((x) => {
        let description = x.description;

        if (x.description.includes('Потеря багажа')) {
          description = 'при пропаже багажа';
        } else if (x.description.includes('Задержка рейса')) {
          description = 'при задержке рейса';
        } else if (x.description.includes('Несчастный случай')) {
          description = 'в несчастном случае';
        } else if (x.description.includes('Задержка багажа')) {
          description = 'при задержке багажа';
        }

        return { ...x, description };
      });
    case 'Отмена поездки':
      return risks.map((x) => ({
        ...x,
        description:
          'в случае внезапной отмены поездки из-за отказа в визе, травмы, госпитализации и еще 13 причин',
      }));
    default:
      return risks;
  }
}

function getWordBeforPrice(productType: string) {
  switch (productType) {
    case 'Страховка от задержки рейса':
    case 'Задержка рейса':
      return 'от';
    case 'Гарантия хорошей погоды для туристов':
    case 'Гарантия хорошей погоды':
      return '';
    default:
      return 'до';
  }
}

function getSpecificationDoc(productType: string) {
  switch (productType) {
    case 'Страховка от задержки рейса':
    case 'Задержка рейса':
    case 'Гарантия хорошей погоды для туристов':
    case 'Гарантия хорошей погоды':
      return '/docs/reno.pdf';
    default:
      return 'http://www.alfastrah.ru/docs/rules_SP_230617.pdf';
  }
}

function getOffertaUri(productType: string) {
  switch (productType) {
    case 'ANTICOVID':
      return 'https://www.alfastrah.ru/docs/Offer_anticovid_2_avia_247.pdf';
    case 'Защита на время полета':
      return 'https://www.alfastrah.ru/docs/Offer_bg_zv_ns_247.pdf';
    case 'Отмена поездки':
      return 'http://www.alfastrah.ru/docs/Offer_nsp_247_se.pdf';
    case 'Online-врач в поездку':
      return 'https://www.alfastrah.ru/docs/telemed_ns_offer.pdf';
    default:
      return null;
  }
}

export function availableInsurancesNormalizer(args: GetAvailableInsurancesDto) {
  const res = {
    total: _(args.items)
      .filter((x) => x.checked)
      .reduce((acc, x) => acc + x.totalRate.value, 0),
    items: _(args.items)
      .map((x) => {
        const productType = (x.product.name || '').trim();

        return {
          ...x,
          wordBeforePrice: getWordBeforPrice(productType),
          iconSrc: getInsuranceIcon(productType),
          company: getCompany(productType),
          loading: false,
          label: getLabel(x.checked),
          offertaUri: getOffertaUri(productType),
          specificationUri: getSpecificationDoc(productType),
          risks: formatRisks(productType, x.risks || []).sort(
            (a, b) => a.coverage.value - b.coverage.value,
          ),
          product: {
            ...x.product,
            description: getInsuranceDescription(productType),
          },
        };
      })
      .value(),
  };

  return res;
}

export const transformOrderToBusForRouteDetail = (
  order: any,
): BusForRouteDetail[] => {
  return order?.busOrderPositions?.map((busOrder: any) => {
    const segment = busOrder?.orderPosition?.segments[0];

    return {
      fromStation: {
        code: segment.departureCityCode,
        name_ru: segment.departureStationName,
        description: null,
      },
      toStation: {
        code: segment.arrivalCityCode,
        name_ru: segment.arrivalStationName,
        description: null,
      },
      isTransit: segment.bus.isTransit,
      carrierDisplayNames: [segment.bus.carrier],
      initialStationName: segment.departureStationName,
      finalStationName: segment.arrivalStationName,
      busName: segment.bus.raceId,
      busNumber: segment.bus.raceNumber,
      tripDuration: formatMinutes(segment.tripDuration || 0),
      departureDateTimeUtc: segment.departureDate,
      arrivalDateTimeUtc: segment.arrivalDate,
      localDepartureDate: segment.departureDate,
      localArrivalDate: segment.arrivalDate,
      index: 0,
      price: segment.price.totalPrice,
      totalPlaceQuantity: segment.places ? segment.places.length : 0,
      raceId: segment.bus.raceId,
      freePlaces: null,
      address: null,
      geolocation: null,
      provider: '',
      place: segment.bus.place || '',
    };
  });
};
