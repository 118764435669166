import React from 'react';
import moment from 'moment';
import { FlightItemActiveProps } from '../../types';
import styled from 'styled-components';
import Text from '@components/ui/Typography';
import MainSegment from './MainSegment';
import SegmentList from './SegmentList';
import { useTicketProps } from '../../useTicketProps';
import FLighTypeIcon from '../../../../common/FlightTypeIcon';
import { LowcosterPopup } from '@components/common/LowcosterNotifications';
import { getFeatureIcon } from '../../utils';
import _ from 'lodash';
import AviaSegmentHeader from '@components/flightsSearch/TicketList/Flight/Desktop/AviaSegmentHeader';
import { FLIGHT_TYPES } from '@components/booking/FlightDetail/types';

const HeadTitle = styled(Text)`
  display: block;
  padding-left: 7px;

  @media (max-width: 767px) {
    display: none;
  }
`;

const Container = styled.div`
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    :not(:first-child) {
      margin-top: 10px;
    }
  }
`;

const Flex = styled.div`
  display: flex;

  & > div + div {
    margin-left: 6px;
  }
`;

const HeadContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  margin-top: 10px;

  @media (max-width: 767px) {
    display: none;
  }
`;

const HeadContainerMobile = styled(HeadContainer)`
  display: none;
  @media (max-width: 767px) {
    display: flex;
    justify-content: space-between;
    margin-top: 0;
  }
`;

const FeaturesWrapper = styled.div`
  display: flex;

  & > div {
    margin-right: 8px;
  }

  & > :last-child {
    margin-right: 0;
  }
`;

const ItemActive: React.FC<FlightItemActiveProps> = (props) => {
  const { segments, stops } = props;
  const fromDate = moment(`${segments[0].fromDate} ${segments[0].fromTime}`);
  const { features } = useTicketProps();

  const toDate = moment(
    `${segments[segments.length - 1].toDate} ${
      segments[segments.length - 1].toTime
    }`,
  );

  const flightNumbers = segments.map(
    (x) => x?.airlineInfo?.operatingAirlineCode + ' ' + x.flightNo,
  );

  return (
    <Container>
      {!props.active && (
        <AviaSegmentHeader
          from={props.from}
          to={props.to}
          airlinesInfo={props.airlinesInfo}
          flightIndex={props.flightIndex}
          segments={props.segments}
        />
      )}

      <HeadContainerMobile>
        <HeadTitle bold color="dark-gray">
          {props.flightIndex ? 'Обратно' : 'Туда'}: {props.from.city} —{' '}
          {props.to.city}
        </HeadTitle>
        <Flex>
          {segments[0].flight_Type !== FLIGHT_TYPES.LOWCOSTER && (
            <FLighTypeIcon flightType={segments[0].flight_Type} />
          )}
          {segments[0].flight_Type === FLIGHT_TYPES.LOWCOSTER && (
            <LowcosterPopup />
          )}
        </Flex>
        <FeaturesWrapper>
          {features.map((feature, index) => {
            return <span key={index}>{getFeatureIcon(feature)}</span>;
          })}
        </FeaturesWrapper>
      </HeadContainerMobile>
      {!props.active && (
        <MainSegment
          // flightNumber={se}
          from={segments[0].from}
          to={segments[segments.length - 1].to}
          fromDate={fromDate}
          toDate={toDate}
          stops={stops}
          duration={props.duration}
          airlines={_(segments)
            .map((x) => x.airlineInfo)
            .uniqBy((x) => x.operatingAirlineCode)
            .value()}
          flightNumbers={flightNumbers}
          techLanding={segments[segments.length - 1].techLandings}
        />
      )}
      {props.active && <SegmentList stops={stops} items={segments} />}
    </Container>
  );
};

export default ItemActive;
