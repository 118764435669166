import React from 'react';
import styled from 'styled-components';
import { ReactComponent as InfoSvg } from '@assets/media/shared/exclamatory.svg';
import { ReactComponent as ClockSvg } from '@assets/media/shared/clock.svg';
import { ReactComponent as Notification } from '@assets/media/shared/notification.svg';
import { ReactComponent as Arrow } from '@assets/media/shared/arrowRight.svg';
import { useDispatch } from 'react-redux';
import { Text } from '@components/ui';
import Tippy from '@tippyjs/react';
import HasConnectionsFlightsModal from './Modals/HasConnectingFlightsModal';
import { stickyFooterHide } from '@modules/orders';

interface LowcosterNotyProps {
  needAddChildNoty?: boolean;
}

const PopupWrapper = styled.div<{ background: string }>`
  background: ${({ background }) => background};
  border-radius: 8px;
  padding: 6px 8px;
  cursor: pointer;

  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  margin-left: 5px;
  color: #000000;

  & > svg {
    margin-right: 3px;
    height: 16px;
    width: 16px;
  }

  @media (max-width: 767px) {
    margin-left: 0;
    padding: 5px 6px;
  }
`;

const PopupTextContainer = styled.div`
  & > * {
    margin-bottom: 7px;
    display: block;
  }

  & > :first-child {
    margin-bottom: 10px;
  }

  & > :last-child {
    margin-bottom: 0;
  }
`;

const NotyContainer = styled.div`
  padding: 14px 24px;
  background: #edfaf9;
  border: 1px solid #8ac5c0;
  border-radius: 8px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 767px) {
    padding: 14px;
  }
`;

const Heading = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: #4872f2;
  margin-bottom: 20px;
`;

const NotyContainerYellow = styled(NotyContainer)`
  background: #fff3cd;
  border: 1px solid #ffb800;
`;

const NotyContent = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 8px;
`;

const NotyTextBlock = styled.div`
  margin-top: 15px;

  &:first-child {
    margin-top: initial;
  }
`;

const ChildNotyContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  & > svg {
    width: 25px;
    height: 25px;
    margin-right: 8px;
    color: #669f9a;
    flex-shrink: 0;
  }
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
`;

export const LowcosterPopup = () => {
  return (
    <Tippy
      maxWidth={181}
      theme="light"
      content={
        'Перевозку совершает авиакомпания-лоукостер. Выписка билетов может занять до 6 часов.'
      }
    >
      <PopupWrapper background={'#EDFAF9'}>
        <InfoSvg />
        <PopupTextContainer>
          <Text size={'small'} fontWeight={'600'}>
            Лоукостер
          </Text>
        </PopupTextContainer>
      </PopupWrapper>
    </Tippy>
  );
};

export const LowcosterShortNotification = () => {
  return (
    <NotyContainer>
      <NotyTextBlock>
        <Text>
          Обращаем Ваше внимание, рейс совершает авиакомпания-лоукостер. Выписка
          билетов может занять до 6 часов.
        </Text>
      </NotyTextBlock>
    </NotyContainer>
  );
};

export const HasConnectingFlightsNotification = () => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const dispatch = useDispatch();

  return (
    <NotyContainer style={{ background: '#FFE2E3' }}>
      {isModalOpen && (
        <HasConnectionsFlightsModal
          setIsModalOpen={setIsModalOpen}
          isModalOpen={isModalOpen}
        />
      )}
      <Flex>
        <Notification />
        <Heading style={{ color: '#3C3C3C', marginBottom: 0, marginLeft: 7 }}>
          Раздельное бронирование
        </Heading>
      </Flex>
      <NotyTextBlock style={{ marginLeft: 32, marginTop: 10 }}>
        <Text>
          Внимание, бронирование включает в себя несколько билетов разных
          авиакомпаний. <br />
          <Flex style={{ marginTop: 5 }}>
            <span
              onClick={() => {
                setIsModalOpen(true);
                dispatch(stickyFooterHide(true));
              }}
              style={{ color: '#4872F2', cursor: 'pointer' }}
            >
              Узнать подробнее
            </span>{' '}
            <Arrow
              onClick={() => {
                setIsModalOpen(true);
                dispatch(stickyFooterHide(true));
              }}
              style={{ cursor: 'pointer' }}
            />
          </Flex>
        </Text>
      </NotyTextBlock>
    </NotyContainer>
  );
};

export const CharterShortNotification = () => {
  return (
    <NotyContainer>
      <NotyTextBlock>
        <Text>
          Выписка авиабилетов по чартерам происходит за день до вылета.
          Уведомление о выписке билетов прийдет на указанную при бронировании
          билетов почту. <br /> <br />
          Условия тарифа и правила возврата по чартерному перелету указаны при
          бронировании и в деталях заказа после оформления авиабилетов.
        </Text>
      </NotyTextBlock>
    </NotyContainer>
  );
};

export const IssueNotification: React.FC<{ isDelayed: boolean }> = ({
  isDelayed,
}) => {
  return (
    <NotyContainer style={{ background: '#FFF3CD' }}>
      <NotyTextBlock>
        <Heading>
          {' '}
          Обращаем внимание, по заказу{' '}
          {isDelayed
            ? 'отложенная выписка билетов!'
            : 'неуспешная выписка билетов'}
        </Heading>
        <Text>
          После оплаты в течение <strong> 6-8 часов </strong> вы получите
          маршрутную квитанцию, в которой номер заказа является и PNR, и номером
          билета.
          <br /> <br />
          Если в течение 6 часов, по каким-либо причинам, авиакомпания всё ещё
          не подтвердит бронирование, мы Вас об этом проинформируем.
        </Text>
      </NotyTextBlock>
    </NotyContainer>
  );
};

export const LowcosterNotification = (props: LowcosterNotyProps) => {
  return (
    <NotyContainer>
      <NotyTextBlock>
        <Text>
          Обращаем внимание, рейс совершает авиакомпания-лоукостер. После оплаты
          в течение <b>6 часов</b> вы получите маршрутную квитанцию, в которой
          номер заказа является и PNR, и номером билета.
        </Text>
      </NotyTextBlock>
      <NotyTextBlock>
        <Text>
          Если в течение 6 часов, по каким-либо причинам, авиакомпания всё ещё
          не подтвердит бронирование, мы Вас об этом проинформируем.
        </Text>
      </NotyTextBlock>
      {props?.needAddChildNoty && (
        <NotyTextBlock>
          <ChildNotyContainer>
            <InfoSvg />
            <Text>
              По правилам авиакомпании-лоукостера для пассажиров возрастом{' '}
              <b>от 2 до 14 лет</b> тип пассажира определяется как "
              <b>Ребенок</b>".
            </Text>
          </ChildNotyContainer>
        </NotyTextBlock>
      )}
    </NotyContainer>
  );
};

export const FinishedOrderPositionNotification = () => {
  return (
    <NotyContainerYellow>
      <NotyContent>
        <ClockSvg />
        <Text>
          Пожалуйста, дождитесь завершения обработки оплаты. Как только
          обработка завершится билеты будут выписаны.
        </Text>
      </NotyContent>
    </NotyContainerYellow>
  );
};
