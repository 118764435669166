import React from 'react';
import styled from 'styled-components';
import { FlightItemPassiveProps } from '../../types';
import { Helper } from '@utils';
import Text from '@components/ui/Typography';
import { Point } from '@components/common/FlightSegment';
import Place from '../../Place';
import { useMediaQuery } from 'react-responsive';
import Tippy from '@tippyjs/react';
import TechLanding from '@components/flightsSearch/TicketList/Ticket/TechLanding';
import AircraftInfoRow from '@components/flightsSearch/TicketList/Flight/Desktop/AircraftInfoRow';

const Time = styled(Text)`
  font-size: 24px;
  line-height: 32px;
  display: inline-block;
  margin-bottom: 3px;

  @media (max-width: 767px) {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
  }
`;

const ItemContainer = styled.li`
  list-style-type: none;
  position: relative;
  font-family: ${({ theme: { fonts } }) => fonts.regular};
`;

const FlightTime = styled.span<{ blue?: boolean }>`
  display: inline;
  font-size: 10px;
  line-height: 14px;
  color: ${({ blue, theme: { colors } }) => (blue ? colors.main : '#737373')};
  text-align: center;
  @media (max-width: 767px) {
    display: none;
  }
`;

const ProgressInfo = styled.div`
  text-align: center;
  width: 100%;
  margin: 0 10px;

  span {
    margin-bottom: 7px;
  }
`;

const ProgressLine = styled.div`
  margin-top: 20px;
  height: 2px;
  background-color: #dcdcdc;
  position: relative;

  & > div {
    top: -8px;
  }

  & > :last-child {
    right: 0;
  }

  @media (max-width: 767px) {
    margin-top: 13px;
    & > div {
      top: -6px;
    }
  }
`;

const PlaceTimeWrapper = styled.div`
  display: flex;
`;

const PlaceDateWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  & > :last-child {
    text-align: right;
  }
`;

const PointsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: absolute;
`;

const CompanyLogoMobile = styled.div`
  display: flex;
  align-items: center;
  margin-top: 12.5px;

  & > :first-child {
    margin-right: 5px;
  }

  @media (min-width: 768px) {
    display: none;
  }
`;

const LogoDescription = styled(Text)`
  font-size: 12px;
  line-height: 16px;
  color: #3c3c3c;
`;

const Image = styled.img.attrs({ width: 21.45, height: 21.45 })`
  border-radius: 50%;
  background: #9097ce;
`;

const PathTime = styled(Text)`
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  color: #737373;
  position: absolute;
  right: 50%;
  transform: translateX(50%);
  bottom: 3px;

  @media (min-width: 768px) {
    display: none;
  }
`;

const MainSegment: React.FC<FlightItemPassiveProps> = ({
  from,
  to,
  fromDate,
  toDate,
  flightNumbers,
  ...props
}) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const travelTime = Helper.formatDuration(props.duration);
  const stopString =
    props.stops.length > 0
      ? `${props.stops.length} ${Helper.pluralWord(
          props.stops.length,
          'пересадка',
          'пересадки',
          'пересадок',
        )}`
      : 'прямой';

  return (
    <div>
      <ItemContainer>
        <PlaceTimeWrapper>
          <Time color="#3C3C3C" bold>
            {fromDate.format('HH:mm')}
          </Time>
          <ProgressInfo>
            <ProgressLine>
              <PointsContainer>
                <Point />
                {!isMobile &&
                  props.stops.map((x, key) => (
                    <Tippy
                      key={key}
                      content={`Пересадка ${Helper.formatDuration(
                        x.duration,
                      )}, аэропорт ${x.city.airport}, ${x.city.city}`}
                      theme="light"
                      maxWidth={190}
                    >
                      <div>
                        <Point key={key} upperText={x.city.code} />
                      </div>
                    </Tippy>
                  ))}
                {props.techLanding.length !== 0 && (
                  <Point color={'red'} techLanding={props.techLanding[0]} />
                )}
                {isMobile && props.stops.length > 0 && (
                  <Point
                    mobileProps={{
                      duration: props.duration,
                      stopDuration: props.stops[0].duration,
                      stopCount: props.stops.length,
                      city: {
                        name: props.stops[0].city.city,
                        code: props.stops[0].city.code,
                      },
                      airport: {
                        name: props.stops[0].city.airport,
                        code: props.stops[0].city.code,
                      },
                    }}
                  />
                )}
                {isMobile && props.stops.length === 0 && (
                  <PathTime>{Helper.formatDuration(props.duration)}</PathTime>
                )}
                <Point />
              </PointsContainer>
            </ProgressLine>
          </ProgressInfo>
          <Time color="#3C3C3C" bold>
            {toDate.format('HH:mm')}
          </Time>
        </PlaceTimeWrapper>
        <PlaceDateWrapper>
          <Place {...from} date={fromDate} />

          <FlightTime>
            В пути: {travelTime},{' '}
            <FlightTime blue={props.stops.length > 0}>{stopString}</FlightTime>{' '}
            {props.techLanding.length !== 0 && <TechLanding />}
          </FlightTime>
          <Place {...to} date={toDate} />
        </PlaceDateWrapper>
      </ItemContainer>
      {props.airlines.map((x, key) => (
        <CompanyLogoMobile key={key}>
          <Image
            src={Helper.getAirlinesLogoUrl(x.operatingAirlineCode, 65, 65)}
            alt={x.operatingAirlineName}
          />{' '}
          <LogoDescription>{x.operatingAirlineName}</LogoDescription>
        </CompanyLogoMobile>
      ))}
      <AircraftInfoRow type={'aircraft'} title={'Рейс'} baseItems={ flightNumbers } />
    </div>
  );
};

export default MainSegment;
