import React, { useMemo, useState, useEffect, useRef } from 'react';
import styled, { keyframes } from 'styled-components';
import Baggage1 from '../../../../assets/images/baggage11';
import Baggage2 from '../../../../assets/images/baggage12';
import BaggageInfo from '../../../../assets/images/baggageInfo';
import IconWithPopover from '../IconWithPopover';
import { Button } from '@components/ui';
import { Helper, Api } from '@utils';
import FlightsList from '../Flight';
import { useHistory } from 'react-router-dom';
import { useTicketProps } from '../useTicketProps';
import MobileBottom from './MobileBottom';
import mobileArrowPath from '@assets/media/flight-search/mobile-arrow.svg';

const shim = keyframes`
    0% {
        background-color: #C8C8C8;
    }

    99% {
        background-color: transparent;
    }

    100% {
        display: none;
    }
`;

const TicketContainer = styled.li`
  position: relative;
  ${({ theme: { colors, fonts } }) => `
        background: ${colors.white};
        border: 1px solid ${colors.lightGray};
        box-sizing: border-box;
        border-radius: 10px;
        font-family: ${fonts.regular};
        list-style-type: none;
        display: flex;
        justify-content: space-between;
        position: relative;
        @media screen and (max-width: 767px) {
          box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
        }
    `}
`;

const Overlay = styled.div`
  position: absolute;
  animation: ${shim} 0.2s linear forwards;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 100;
`;

const ItemLeftSide = styled.div`
  border-right: 1px solid ${({ theme: { colors } }) => colors.lightGray};
  padding: 20px 28px;
  text-align: left;

  & .segments {
    min-height: 135px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    & > :first-child > h6 {
      margin-top: 0;
    }
  }

  width: 70%;
  @media screen and (min-width: 1188px) {
    width: 100%;
    max-width: 600px;
  }
  @media screen and (max-width: 767px) {
    border-right: none;
    padding: 14px 0 9px 0;
    width: 100%;
  }
`;

const ItemRightSide = styled.div`
  width: 225px;
  position: relative;
  padding: 20px 25px;
  @media screen and (max-width: 767px) {
    display: none;
  }
`;

const BodyForm = styled.form`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  gap: 15px;

  & .buy-button {
    padding: 8px 20px;
  }
`;
const BodyFormLinkContainer = styled.div`
  margin-top: auto;
`;

const BodyFormLink = styled.a`
  outline: none;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  margin-bottom: 0;
  align-self: stretch;
  color: ${({ theme: { colors } }) => colors.main};
  text-decoration: none;
`;

const BaggageContainer = styled.div`
  display: flex;
  height: 83px;
  justify-content: center;
  align-items: flex-end;
`;

const MobileDetailButton = styled.button`
  @media (min-width: 768px) {
    display: none;
  }

  text-decoration: none;
  border: none;
  padding: 0;
  outline: none;
  display: block;
  margin-bottom: 12px;
  margin-left: 14px;
  background: transparent;
  position: relative;

  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #4872f2;
`;

const MobileArrow = styled.img<{ reverse: boolean }>`
  position: absolute;
  right: -16px;
  top: 6px;
  width: 10px;
  transition: transform 0.2s ease-in-out;

  ${({ reverse }) => (reverse ? 'transform: rotate(180deg);' : '')}
`;

const Ticket: React.FC = () => {
  const { flights, prices, airlinesInfo, onClick, ...props } = useTicketProps();
  const history = useHistory();

  const totalPrice = useMemo(() => {
    return Helper.formatPrice(prices[0]);
  }, [prices]);

  const [active, setActive] = useState(false);
  const [offsetBeforeClick, setOffset] = useState(0);
  const [cachedActive, seCachedActive] = useState(false);

  const ref = useRef(null as null | HTMLLIElement);
  useEffect(() => {
    if (active !== cachedActive && ref.current) {
      const { y } = ref.current.getBoundingClientRect();
      const windowOffset = window.scrollY;
      window.scrollTo({ top: windowOffset + (y - offsetBeforeClick) });
      seCachedActive(active);
    }
  }, [active, offsetBeforeClick, cachedActive]);

  const handleBuyClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    const path = `/booking/${props.searchId}/?${Api.createQueryParams({
      group: props.groupIndex,
      flights: flights.map((flight) => flight.index),
    })}`;
    e.preventDefault();
    if (process.env.REACT_APP_MODE === 'test' && (e.ctrlKey || e.metaKey)) {
      window.open(path, '_blank');
    } else {
      history.push(path);
    }
  };

  const handleDetailClick = (e: any) => {
    e.preventDefault();
    if (ref.current) {
      setOffset(ref.current.getBoundingClientRect().y);
    }
    setActive((a) => {
      return !a;
    });
    typeof onClick === 'function' && onClick();
  };

  return (
    <TicketContainer ref={ref}>
      <Overlay
        onAnimationEnd={(e) => {
          (e.target as HTMLDivElement).style.display = 'none';
        }}
      />
      <ItemLeftSide>
        <FlightsList
          active={active}
          className="segments"
          items={flights}
          airlinesInfo={airlinesInfo}
        />
        {flights.find((x) => x.stops.length !== 0) && (
          <MobileDetailButton onClick={handleDetailClick}>
            Детали перелета{' '}
            <MobileArrow src={mobileArrowPath} reverse={active} />
          </MobileDetailButton>
        )}

        <MobileBottom />
      </ItemLeftSide>
      <ItemRightSide>
        <BodyForm>
          <BaggageContainer>
            {props.baggage ? (
              props.baggage.value > 0 ? (
                <IconWithPopover
                  normalizePosition
                  tip={`Включён багаж ${props.baggage.value}`}
                >
                  <Baggage1 value={props.baggage.value} />
                </IconWithPopover>
              ) : props.baggage.value === -10 ? (
                <IconWithPopover normalizePosition tip={props.baggage.content}>
                  <BaggageInfo />
                </IconWithPopover>
              ) : (
                <IconWithPopover normalizePosition tip={'Багаж отсутствует'}>
                  <Baggage2 />
                </IconWithPopover>
              )
            ) : (
              <IconWithPopover
                normalizePosition
                tip={'Нет информации о багаже'}
              >
                <Baggage1 value="?" />
              </IconWithPopover>
            )}
          </BaggageContainer>
          <Button
            data-gds={props.airGds}
            data-cy={`buyAviaTicketButton_${props.id}`}
            onClick={handleBuyClick}
            className="buy-button"
            color="yellow"
            htmlType="submit"
            style={{ fontWeight: 'normal', whiteSpace: 'nowrap' }}
          >
            Купить за {totalPrice}
          </Button>
          <BodyFormLinkContainer>
            <BodyFormLink href="test.ru" onClick={handleDetailClick}>
              {active ? 'Скрыть детали' : 'Открыть детали'}
            </BodyFormLink>
          </BodyFormLinkContainer>
        </BodyForm>
      </ItemRightSide>
    </TicketContainer>
  );
};

export default Ticket;
