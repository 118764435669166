import React from 'react';
import styled from 'styled-components';
import { Helper } from '@utils';
import { EnhancedFlightSegment, Stop } from '@modules/main';
import Place from '../../Place';
import { Point } from '@components/common/FlightSegment';
import moment from 'moment';
import { Text } from '@components/ui';
import PlaneIcon from '@assets/images/plane';
import techInfoIcon from '@assets/media/flight-search/techInfoIcon.svg';
import iconFlightReturnRed from '@assets/media/flight-search/iconFlightReturnRed.svg';
import luggageIconRed from '@assets/media/flight-search/luggageIconRed.svg';
import terminalIconRed from '@assets/media/flight-search/terminalIconRed.svg';
import Tippy from '@tippyjs/react';

import ManIcon from '@assets/images/man';
import { cityIn } from 'lvovich';
import AviaSegmentHeader from '@components/flightsSearch/TicketList/Flight/Desktop/AviaSegmentHeader';
import AircraftInfoRow from '@components/flightsSearch/TicketList/Flight/Desktop/AircraftInfoRow';
import StopSeparator from '@components/flightsSearch/TicketList/Flight/Desktop/StopSeparator';
import Chip from '@components/ui/Chip/Chip';
import { useMediaQuery } from 'react-responsive';
import { TransferProps } from '@components/flightsSearch/TicketList/Flight/types';

const Container = styled.div``;

const LineWrapper = styled.div`
  display: flex;
`;

const Time = styled(Text)`
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;

  @media (max-width: 767px) {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
  }
`;

const PlaceDateWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  & > :last-child {
    text-align: right;
  }

  margin-top: 3px;
`;

const PointsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: absolute;
`;

const ProgressLine = styled.div`
  margin-top: 20px;
  height: 2px;
  background-color: #dcdcdc;
  position: relative;

  & > div {
    top: -8px;
  }

  & > :last-child {
    right: 0;
  }

  @media (max-width: 767px) {
    margin-top: 13px;
    & > div {
      top: -6px;
    }
  }
`;

const ProgressInfo = styled.div`
  text-align: center;
  width: 100%;
  margin: 0 10px;

  span {
    margin-bottom: 7px;
  }
`;

const DescriptionBlock = styled.div`
  background: #f7f7f7;
  border-radius: 4px;
  padding: 10px 12px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 0;

  & > div {
    margin-bottom: 10px;
  }

  & > :last-child {
    margin-bottom: 0;
  }

  @media (max-width: 767px) {
    padding: 8px;
  }
`;

const CompanyLogoMobile = styled.div`
  display: flex;
  align-items: center;
  margin-top: 12.5px;

  & > :first-child {
    margin-right: 5px;
  }

  @media (min-width: 768px) {
    display: none;
  }
`;

const LogoDescription = styled(Text)`
  font-size: 12px;
  line-height: 16px;
  color: #3c3c3c;
`;

const Image = styled.img.attrs({ width: 21.45, height: 21.45 })`
  border-radius: 50%;
  background: #9097ce;
`;

const PathTime = styled(Text)`
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  color: #737373;
`;

const AdditionalIcon = styled.img`
  width: 16px;
  height: 16px;
`;

const DescriptionItem = styled.div`
  display: flex;
  gap: 4px;
`;

const DescriptionAdditionalItem = styled(DescriptionItem)`
  gap: 10px;
  flex-wrap: wrap;
`;

const MutedText = styled(Text)`
  color: #737373;
`;

type SegmentInfoProps = EnhancedFlightSegment & {
  stop: TransferProps | undefined;
};

const SegmentInfo: React.FC<SegmentInfoProps> = (props) => {
  const { from, to, airlineInfo, stop, techLandings } = props;
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const fromDate = moment(`${props.fromDate} ${props.fromTime}`);
  const toDate = moment(`${props.toDate} ${props.toTime}`);
  const hasTechLanding = techLandings && techLandings.length > 0;
  const hasAdditionalInfo =
    stop?.isDifferentAirports || stop?.isDifferentTerminals || hasTechLanding;
  const stopTitle = stop
    ? `пересадка в ${cityIn(stop.city.city)} ${Helper.formatDuration(stop.duration)}`
    : '';
  const techLandingsTitle = hasTechLanding
    ? `тех. остановка в ${techLandings[0].city.airport} ${techLandings[0].city.city} ${Helper.formatDuration(techLandings[0].duration)}`
    : '';

  return (
    <Container>
      <AviaSegmentHeader
        from={props.from}
        to={props.to}
        airlinesInfo={[{ name: airlineInfo.name, code: airlineInfo.code }]}
        segments={[{ flight_Type: props.flight_Type }]}
      />

      <LineWrapper>
        <Time>{fromDate.format('HH:mm')}</Time>
        <ProgressInfo>
          <ProgressLine>
            <PointsContainer>
              <Point />
              <Point />
            </PointsContainer>
          </ProgressLine>
        </ProgressInfo>
        <Time>{toDate.format('HH:mm')}</Time>
      </LineWrapper>
      <PlaceDateWrapper>
        <Place {...props.from} date={fromDate} />
        <PathTime>{Helper.formatDuration(props.duration)}</PathTime>
        <Place {...props.to} date={toDate} />
      </PlaceDateWrapper>
      <CompanyLogoMobile>
        <Image
          src={Helper.getAirlinesLogoUrl(
            props.airlineInfo.operatingAirlineCode,
            65,
            65,
          )}
          alt={props.airlineInfo.operatingAirlineName}
        />{' '}
        <LogoDescription>
          {props.airlineInfo.operatingAirlineName}
        </LogoDescription>
      </CompanyLogoMobile>
      <AircraftInfoRow
        title={'Рейс'}
        type={'aircraft'}
        baseItems={[`${props.airlineCode} ${props.flightNo}`]}
        additionalItem={props.airplane}
      />
      {/*<AircraftInfoRow*/}
      {/*  title={'Багаж'}*/}
      {/*  type={'luggage'}*/}
      {/*  baseItems={[`{BAGGAGE}`]}*/}
      {/*/>*/}
      <DescriptionBlock>
        {(from.terminal || to.terminal) && (
          <DescriptionItem>
            <MutedText size={isMobile ? 'small' : 'normal'}>
              Терминалы:
            </MutedText>
            <Text size={isMobile ? 'small' : 'normal'}>
              {from.terminal ? `вылет — ${from.terminal}` : ''}
              {to.terminal ? `, прилёт — ${to.terminal}` : ''}
            </Text>
          </DescriptionItem>
        )}
        <DescriptionItem>
          <MutedText size={isMobile ? 'small' : 'normal'}>
            Рейс выполняет:
          </MutedText>
          <Text size={isMobile ? 'small' : 'normal'}>
            {airlineInfo.operatingAirlineName}
          </Text>
        </DescriptionItem>
        {hasAdditionalInfo && (
          <DescriptionAdditionalItem>
            {hasTechLanding && (
              <Tippy content={techLandingsTitle} theme={'light'}>
                <Chip
                  icon={<AdditionalIcon src={techInfoIcon} />}
                  bgColor={'red'}
                  label={'Техническая остановка'}
                  adaptiveTextColor
                />
              </Tippy>
            )}

            {stop?.isDifferentAirports && (
              <Tippy content={'Смена аэропорта'} theme={'light'}>
                <Chip
                  icon={<AdditionalIcon src={iconFlightReturnRed} />}
                  bgColor={'red'}
                  label={'Смена аэропорта'}
                  adaptiveTextColor
                />
              </Tippy>
            )}
            {!stop?.isDifferentAirports && stop?.isDifferentTerminals && (
              <Tippy content={'Смена терминала'} theme={'light'}>
                <Chip
                  icon={<AdditionalIcon src={terminalIconRed} />}
                  bgColor={'red'}
                  label={'Смена терминала'}
                  adaptiveTextColor
                />
              </Tippy>
            )}

            {/*</Tippy>*/}
            {/*<Tippy content={'Регистрация багажа'} theme={'light'}>*/}
            {/*  <Chip*/}
            {/*    icon={<AdditionalIcon src={luggageIconRed} />}*/}
            {/*    bgColor={'red'}*/}
            {/*    label={'Регистрация багажа'}*/}
            {/*    adaptiveTextColor*/}
            {/*  />*/}
            {/*</Tippy>*/}
          </DescriptionAdditionalItem>
        )}
      </DescriptionBlock>
      {props.stop && <StopSeparator type={'normal'} title={stopTitle} />}
      {props.techLandings && props.techLandings.length > 0 && (
        <StopSeparator type={'technical'} title={techLandingsTitle} />
      )}
    </Container>
  );
};

export default SegmentInfo;
