import React from 'react';
import styled from 'styled-components';
import Text from '@components/ui/Typography';
import aircraftIcon from '@assets/media/flight-search/flightNumberIcon.svg';
import luggageIcon from '@assets/media/flight-search/luggage-round-icon.svg';
import { useMediaQuery } from 'react-responsive';

interface Props {
  title: string;
  baseItems: string[];
  additionalItem?: string;
  type: 'aircraft' | 'luggage';
}

const Row = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

const Icon = styled.img`
  margin-right: 7px;
  width: 25px;
  height: 25px;
`;

const MutedText = styled(Text)`
  color: #737373;
`;

const AircraftInfoRow: React.FC<Props> = (props) => {
  const { title, baseItems, additionalItem, type } = props;
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <Row>
      <Icon src={type === 'aircraft' ? aircraftIcon : luggageIcon} />
      <MutedText size={isMobile ? 'small' : 'normal'}>{title}:&nbsp;</MutedText>
      <Text size={isMobile ? 'small' : 'normal'}>
        {baseItems.join(', ')} {additionalItem ? ` • ${additionalItem}` : ''}
      </Text>
    </Row>
  );
};

export default AircraftInfoRow;
