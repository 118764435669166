import React from 'react';
import styled from 'styled-components';
import Text from '@components/ui/Typography';
import FLighTypeIcon from '../../../../common/FlightTypeIcon';
import Tippy from '@tippyjs/react';
import { Helper } from '@utils';
import AviaFeatures from '@components/flightsSearch/TicketList/Ticket/AviaFeatures';
import { FlightType } from '@components/booking/FlightDetail/types';

interface Props {
  from: {
    city: string;
  };
  to: {
    city: string;
  };
  airlinesInfo: {
    name: string;
    code: string;
  }[];
  flightIndex?: number;
  segments: {
    flight_Type: FlightType;
  }[];
}

const HeadTitle = styled(Text)`
  display: block;
  padding-left: 7px;

  @media (max-width: 767px) {
    display: none;
  }
`;

const Flex = styled.div`
  display: flex;

  & > div + div {
    margin-left: 6px;
  }
`;

const AirlineLogo = styled.img.attrs({ width: 25, height: 25 })`
  border-radius: 50%;
`;

const HeadContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  margin-top: 10px;

  @media (max-width: 767px) {
    display: none;
  }
`;

const HeadLeftPart = styled.div`
  display: flex;
  align-items: center;
`;

const HeadRightPart = styled.div`
  display: flex;
  align-items: center;
`;

const getAirlineLogoUrl = (
  airlinesInfo: Props['airlinesInfo'],
  flightIndex?: number,
) => {
  const airline =
    flightIndex === 0 ? airlinesInfo[0] : airlinesInfo[1] || airlinesInfo[0];
  return Helper.getAirlinesLogoUrl(airline?.code, 50, 50, false);
};

const getAirlineName = (
  airlinesInfo: Props['airlinesInfo'],
  flightIndex?: number,
) => {
  return flightIndex === 0
    ? airlinesInfo[0]?.name
    : airlinesInfo[1]?.name || airlinesInfo[0]?.name;
};

const AviaSegmentHeader: React.FC<Props> = ({
  from,
  to,
  airlinesInfo,
  flightIndex,
  segments,
}) => (
  <HeadContainer>
    <HeadLeftPart>
      <Tippy
        content={getAirlineName(airlinesInfo, flightIndex)}
        offset={[0, 16.5]}
      >
        <AirlineLogo src={getAirlineLogoUrl(airlinesInfo, flightIndex)} />
      </Tippy>
      <HeadTitle fontWeight={'600'}>
        {flightIndex !== undefined ? (flightIndex ? 'Обратно:' : 'Туда:') : ''}{' '}
        {from.city} — {to.city}
      </HeadTitle>
      <Flex>
        <FLighTypeIcon flightType={segments[0].flight_Type} />
      </Flex>
    </HeadLeftPart>
    <HeadRightPart>
      <AviaFeatures />
    </HeadRightPart>
  </HeadContainer>
);

export default AviaSegmentHeader;
