import React from 'react';
import { FlightSegmentProps, Separator } from '@components/common';
import { FLIGHT_TYPES, TicketItemProps } from '../types';
import Text from '@components/ui/Typography';
import Segment from '../Segment';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import FLighTypeIcon from '@components/common/FlightTypeIcon';
import { LowcosterPopup } from '@components/common/LowcosterNotifications';
import _ from 'lodash';
import FlightSegment from '@components/common/FlightSegment/FlightSegment';
import { ApplicationState } from '@modules/index';
import { useSelector } from 'react-redux';

const Container = styled.div``;

const Content = styled.div`
  padding: 0 24px;
  padding-bottom: 24px;
  &:last-child {
    padding-bottom: 0;
  }

  ${({ theme }) => `
    ${theme.max('767px')} {
      padding: 0 14px;
      padding-bottom: 0;
    }
  `}
`;

const SegmentsContainer = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  @media (max-width: 1024px) {
    margin-top: 0;
  }
`;

const FromToText = styled(Text)`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #737373;

  @media (max-width: 1024px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

const StyledSeparator = styled(Separator)`
  margin-top: 0;
`;

const FromTo = styled.div`
  display: flex;
  margin-top: 15px;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 1024px) {
    margin-top: 0;
    margin-bottom: 35px;
  }

  @media (max-width: 767px) {
    margin-bottom: 20px;
    display: none;
    & > :last-child {
      display: none;
    }
  }
`;

const MobileSeparator = styled.div`
  width: 100%;
  height: 1px;
  background: #f2f2f2;
  margin: 14px 0;

  @media (min-width: 768px) {
    display: none;
  }
`;

const NewItem: React.FC<TicketItemProps> = React.memo(
  ({ separator, segments, stops, flightIndex, baggageDescr, ...props }) => {
    const from = segments[0].from.city;
    const to = segments[segments.length - 1].to.city;
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const endSegment = segments[segments.length - 1];

    const detailProps: FlightSegmentProps = {
      baggageDescr: baggageDescr,
      from: {
        ...segments[0],
        date: `${segments[0].fromDate} ${segments[0].fromTime}`,
        city: { name: segments[0].from.city, code: '' },
        airport: {
          name: segments[0].from.airport,
          code: segments[0].from.code,
        },
        terminal: segments[0].from.terminal,
      },
      to: {
        ...endSegment.to,
        date: `${endSegment.toDate} ${endSegment.toTime}`,
        city: { name: endSegment.to.city, code: '' },
        airport: { name: endSegment.to.airport, code: endSegment.to.code },
        terminal: endSegment.to.terminal,
      },
      duration: props.duration,
      airlines: _.uniqBy(
        segments.map((x) => ({ name: x.airline, code: x.airlineCode })),
        'name',
      ),
      company: segments[0].operatingAirlineCode,
      companyName: segments[0].operatingAirline,
      active: props.active,
      flightNo: ``,
      stops: stops.map((x) => ({
        duration: x.duration,
        time: x.time,
        city: {
          ...x.city,
          terminal: '',
        },
      })),
    };

    return (
      <Container>
        <Content>
          <FromTo>
            <FromToText>
              {formatDirectionName(flightIndex, props.length)}
              {from}-{to}
            </FromToText>
            {segments[0].flight_Type !== FLIGHT_TYPES.LOWCOSTER && (
              <FLighTypeIcon flightType={segments[0].flight_Type} />
            )}
            {segments[0].flight_Type === FLIGHT_TYPES.LOWCOSTER && <LowcosterPopup />}
          </FromTo>
          <SegmentsContainer>
            {props.active ? (
              segments.map((segment, key) => (
                <Segment
                  key={key}
                  fromTo={
                    key === 0
                      ? `${formatDirectionName(
                          flightIndex,
                          props.length,
                        )} ${from}-${to}`
                      : undefined
                  }
                  baggageDescr={segment.luggageDescription}
                  flightType={props.flight_Type}
                  active={props.active}
                  separator={key !== segments.length - 1}
                  transfer={stops[key]}
                  {...segment}
                />
              ))
            ) : (
              <FlightSegment {...detailProps} />
            )}
          </SegmentsContainer>
        </Content>
        {!isMobile && separator && <StyledSeparator />}
        {isMobile && separator && <MobileSeparator />}
      </Container>
    );
  },
);

function formatDirectionName(index: number, length: number) {
  if (length === 1) return '';
  return index === 0 ? 'Туда: ' : 'Обратно: ';
}

export default NewItem;
