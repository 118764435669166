import moment, { Moment } from 'moment';
import { AVIA_SERVICE_HOST, COUNTRIES_SERVICE_HOST } from '@modules/constants';
import { FlightClassTypes } from '@modules/main';
import { UserCookie } from '@services/UserCookies';
import { Helper } from './index';

const IntlObj = new Intl.NumberFormat('ru-RU', {
  style: 'currency',
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
  currency: 'rub',
});

const IntlObjWithoutStyle = new Intl.NumberFormat('ru-RU', {
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
  currency: 'rub',
});

const IntlObjRailway = new Intl.NumberFormat('ru-RU', {
  style: 'currency',
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
  currency: 'rub',
});

/**
 * Price formatter
 * @param price
 * @returns formatted price in rub currency
 * @example formatPrice(1000000); //1 000 000₽
 */
export function formatPrice(price: number, noStyle?: boolean) {
  return noStyle ? IntlObjWithoutStyle.format(price) : IntlObj.format(price);
}

export function formatPriceRailway(price: number, noStyle?: boolean) {
  return noStyle
    ? IntlObjWithoutStyle.format(price)
    : IntlObjRailway.format(price);
}

/**
 * Transform word endings depending on quantity
 *
 * @param {number} number For wich number resolve word ending
 * @param {string} singular Example : банан
 * @param {string} imperative Example : банана
 * @param {string} subordinative Example : бананов
 * @return {string} word with correct ending
 */
export function pluralWord(
  number: number,
  singular: string,
  imperative: string,
  subordinative: string,
): string {
  const condition = number % 100;
  const condition2 = condition % 10;
  if (condition > 10 && condition < 20) return subordinative;
  if (condition2 > 1 && condition2 < 5) return imperative;
  if (condition2 === 1) return singular;
  return subordinative;
}

/**
 * Translate passenger type
 * @param x0 passenger type
 */
export const translatePassengerAgeCategory = (x: string) => {
  switch (x.toLowerCase()) {
    case 'adult':
      return 'Взрослый';
    case 'child':
      return 'Ребёнок';
    default:
      return 'Младенец';
  }
};

/**
 *
 * @param minutes number of minutes
 * @returns Formatted time
 * @example formattedTimeFromMinutes(125) //2ч 5мин
 */
export function formatDuration(minutes: number) {
  let str = '';
  let days = 0;
  if (minutes >= 60 * 24) {
    days = Math.floor(minutes / (60 * 24));
    str += `${days} д `;
    minutes -= days * 60 * 24;
  }
  if (minutes >= 60 || days > 0) {
    const hours = Math.floor(minutes / 60);
    if (hours !== 0) {
      str += `${hours} ч `;
      minutes = Math.ceil(minutes - hours * 60);
    }
  }

  if (minutes > 0) {
    str += `${minutes} мин`;
  }
  return str;
}

export function momentFormatDuration(duration: Moment) {
  const days = duration.days();
  const hours = duration.hours();
  const minutes = duration.minutes();

  let result = '';
  if (days > 0) {
    result += `${days} ${Helper.pluralWord(days, 'день', 'дня', 'дней')}, `;
  }
  if (hours > 0) {
    result += `${hours} ${Helper.pluralWord(hours, 'час', 'часа', 'часов')}, `;
  }
  if (minutes > 0) {
    result += `${minutes} ${Helper.pluralWord(minutes, 'минута', 'минуты', 'минут')}, `;
  }

  return result;
}

export function formatDurationWithSeconds(seconds: number) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  if (hours > 0) {
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  } else {
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  }
}

export const getAirlinesLogoUrl = (
  code: string,
  width?: number,
  height?: number,
  standart?: boolean,
) =>
  `http://mpics.avs.io/${!standart ? 'al_square/' : ''}${width || 64}/${
    height || 64
  }/${code}.png`;

const zipFlightClass = (flightClass: FlightClassTypes) => {
  switch (flightClass) {
    case FlightClassTypes.Econom:
      return 'E';
    case FlightClassTypes.Comfort:
      return 'C';
    case FlightClassTypes.Bussines:
      return 'B';
    case FlightClassTypes.FirstClass:
      return 'F';
  }
};

const unzipFLightCLass = (char: string) => {
  switch (char) {
    case 'E':
      return FlightClassTypes.Econom;
    case 'C':
      return FlightClassTypes.Comfort;
    case 'B':
      return FlightClassTypes.Bussines;
    case 'F':
      return FlightClassTypes.FirstClass;
    default:
      return FlightClassTypes.Econom;
  }
};

export function parseSearchParams(str: string) {
  const origin = str.substring(0, 3);
  const forwardDate = str.substring(3, 9);
  const destination = str.substring(9, 12);

  str = str.replace(origin + forwardDate + destination, '');

  let backwardDate: string | null = str.substring(0, 6);
  let flightClass = FlightClassTypes.Econom;

  const searchResultFlightClass = str.match(/[A-Z]/);
  if (searchResultFlightClass) {
    flightClass = unzipFLightCLass(searchResultFlightClass[0]);

    str = str.replace(searchResultFlightClass[0], '');
  }

  if (backwardDate.length < 6 || !!backwardDate.match(/[A-Z]/)) {
    backwardDate = null;
  } else {
    str = str.replace(backwardDate, '');
  }

  const adults = parseInt(str[0]);
  const children = str[1] ? parseInt(str[1]) : 0;
  const infants = str[2] ? parseInt(str[2]) : 0;

  return {
    origin,
    destination,
    forwardDate,
    backwardDate,
    adults,
    children,
    infants,
    flightClass,
  };
}

type createSearchParamsProps = {
  fromCode: string;
  ToCode: string;
  forwardDate: Moment;
  backwardDate: Moment | null;
  adults: number;
  children: number;
  infants: number;
  flightClass: FlightClassTypes;
};

export function createSearchParams({
  fromCode,
  ToCode,
  forwardDate,
  backwardDate,
  adults,
  children,
  infants,
  flightClass,
}: createSearchParamsProps) {
  let result = `${fromCode}${forwardDate.format('DDMMYY')}${ToCode}`;
  if (backwardDate) {
    result += `${backwardDate.format('DDMMYY')}`;
  }

  const flightClassFormated =
    flightClass === FlightClassTypes.Econom ? '' : zipFlightClass(flightClass);
  const passengers = `${adults}${children ? children : infants ? 0 : ''}${
    infants ? infants : children ? 0 : ''
  }`;

  return result + flightClassFormated + passengers;
}

export async function searchCountryByName(str: string) {
  try {
    const response = await fetch(
      `${AVIA_SERVICE_HOST}${process.env.REACT_APP_COUNTRIES_SEARCH_API}?value=${str}`,
    );
    const data = await response.json();
    return data as {
      data: {
        id: string;
        isoCode: string;
        nameEng: string;
        nameRus: string;
        isVisible: boolean;
      }[];
      error: null;
      pageCount: number;
    };
  } catch (e) {
    // console.log(e);
  }
}

export async function fetchCountriesList() {
  try {
    const response = await fetch(
      `${COUNTRIES_SERVICE_HOST}${process.env.REACT_APP_COUNTRIES_LIST_API}?Page=1&PageSize=250`,
    );
    const data = await response.json();
    return data as {
      data: {
        id: string;
        isoCode: string;
        nameEng: string;
        nameRus: string;
        isVisible: boolean;
      }[];
      error: null;
      pageCount: number;
    };
  } catch (e) {
    // console.log(e);
  }
}

export async function getPasList(str: string) {
  try {
    const response = await fetch(
      `http://order.tvm-tte-k8s.fstravel.com/api/CustomerPassenger?PageSize=1000&SearchText=${str}`,
      {
        headers: { Authorization: `Bearer ${UserCookie.token}` },
      },
    );

    const data = await response.json();
    return data;
  } catch (e) {
    // console.log(e);
  }
}

export const getCountrySearchList = async (str: string) => {
  //TODO: вынести в функции
  const response = await searchCountryByName(str);
  return response
    ? response.data.map((city) => ({
        label: city.nameRus,
        value: city.isoCode,
      }))
    : [];
};

export const getCountryList = async () => {
  const response = await fetchCountriesList();
  return response
    ? response.data.map((city) => ({
        label: city.nameRus,
        value: city.isoCode,
      }))
    : [];
};

const CHAR_ARR = {
  Ё: 'YO',
  Й: 'I',
  Ц: 'TS',
  У: 'U',
  К: 'K',
  Е: 'E',
  Н: 'N',
  Г: 'G',
  г: 'g',
  Ш: 'SH',
  Щ: 'SCH',
  З: 'Z',
  Х: 'H',
  Ъ: '',
  ё: 'yo',
  й: 'i',
  ц: 'ts',
  у: 'u',
  к: 'k',
  е: 'e',
  н: 'n',
  ш: 'sh',
  щ: 'sch',
  з: 'z',
  х: 'h',
  ъ: '',
  Ф: 'F',
  Ы: 'I',
  В: 'V',
  А: 'a',
  П: 'P',
  Р: 'R',
  О: 'O',
  Л: 'L',
  Д: 'D',
  Ж: 'ZH',
  Э: 'E',
  ф: 'f',
  ы: 'i',
  в: 'v',
  а: 'a',
  п: 'p',
  р: 'r',
  о: 'o',
  л: 'l',
  д: 'd',
  ж: 'zh',
  э: 'e',
  Я: 'Ia',
  Ч: 'CH',
  С: 'S',
  М: 'M',
  И: 'I',
  Т: 'T',
  Ь: '',
  Б: 'B',
  Ю: 'YU',
  я: 'ia',
  ч: 'ch',
  с: 's',
  м: 'm',
  и: 'i',
  т: 't',
  ь: '',
  б: 'b',
  ю: 'yu',
} as { [key: string]: string };

export function translateToLatin(word: string) {
  return word
    .split('')
    .map(function (char) {
      return CHAR_ARR[char] !== undefined ? CHAR_ARR[char] : char;
    })
    .join('');
}

interface FormikErrors {
  [key: string]: any;
}

const CHAR_ARR_SAMO = {
  зг: 'zgh',
  Зг: 'Zgh',
  А: 'A',
  а: 'a',
  Б: 'B',
  б: 'b',
  В: 'V',
  в: 'v',
  Г: 'G',
  г: 'g',
  Д: 'D',
  д: 'd',
  Е: 'E',
  е: 'e',
  Ё: 'E',
  ё: 'e',
  Ж: 'ZH',
  ж: 'zh',
  З: 'Z',
  з: 'z',
  И: 'I',
  и: 'i',
  Й: 'I',
  й: 'i',
  К: 'K',
  к: 'k',
  Л: 'L',
  л: 'l',
  М: 'M',
  м: 'm',
  Н: 'N',
  н: 'n',
  О: 'O',
  о: 'o',
  П: 'P',
  п: 'p',
  Р: 'R',
  р: 'r',
  С: 'S',
  с: 's',
  Т: 'T',
  т: 't',
  У: 'U',
  у: 'u',
  Ф: 'F',
  ф: 'f',
  Х: 'KH',
  х: 'kh',
  Ц: 'TS',
  ц: 'ts',
  Ч: 'CH',
  ч: 'ch',
  Ш: 'SH',
  ш: 'sh',
  Щ: 'SHSC',
  щ: 'shch',
  Ы: 'Y',
  ы: 'y',
  Э: 'E',
  э: 'e',
  Ю: 'IU',
  ю: 'iu',
  Я: 'IA',
  я: 'ia',
  Ь: '',
  ь: '',
  Ъ: '',
  ъ: '',
  "'": '',
  Ї: 'Yi',
  ї: 'i',
  Ґ: 'G',
  ґ: 'g',
  Є: 'Ye',
  є: 'ie',
  І: 'I',
  і: 'i',
} as { [key: string]: string };

export function translateToLatinSamo(word: string) {
  //
  return word
    .split('')
    .map(function (char, i) {
      if (char === 'г' || char === 'Г') {
        return word[i - 1] === 'з'
          ? 'gh'
          : word[i - 1] === 'З'
          ? 'gh'
          : CHAR_ARR_SAMO[char];
      }
      return CHAR_ARR_SAMO[char] !== undefined ? CHAR_ARR_SAMO[char] : char;
    })

    .join('');
}

export const createErrorLabel = (errors: FormikErrors) => {
  return (
    'Ошибка валидации полей ' +
    Object.keys(errors).reduce((acc, x) => acc + `${x},`, '')
  );
};

export function dateToLocal(date: string) {
  const stillUtc = moment.utc(date).toDate();
  return moment(stillUtc).local();
}

export const getMainTemplateElement = (): HTMLElement | null => {
  return document.querySelector('#main-template');
};

export const scrollToMainTemplate = () => {
  const main = getMainTemplateElement();
  if (main) {
    window.scrollTo({
      top: main.offsetTop,
    });
  }
};

export const getShortName = (
  firstName: string,
  lastName: string,
  patronymic: string,
) => {
  const firstLetter = firstName ? firstName[0] + '.' : '';
  const secondLetter = patronymic ? patronymic[0] + '.' : '';
  return `${lastName} ${firstLetter}${secondLetter}`;
};

export function getUniqueItems(addictionalServices: any) {
  const uniqueIds = new Set(
    addictionalServices.items.map((item: any) => item.id),
  );

  const itemsWithUniqueIds = [...uniqueIds]
    .map((id) => addictionalServices.items.find((item: any) => item.id === id))
    .filter(Boolean);

  return itemsWithUniqueIds;
}
