import React from 'react';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { useTicketProps } from '../useTicketProps';
import Text from '@components/ui/Typography';
import { Helper } from '@utils';
import { getFeatureIcon } from '../utils';
import carryOnIncluded from '@assets/media/tariff-features/carryOnIncluded.svg';
import carryOnAtCharge from '@assets/media/tariff-features/carryOnAtCharge.svg';
import carryOnNotOffered from '@assets/media/tariff-features/carryOnNotOffered.svg';

import exchangeIncluded from '@assets/media/tariff-features/exchangeIncluded.svg';
import exchangeAtCharge from '@assets/media/tariff-features/exchangeAtCharge.svg';
import exchangeNotOffered from '@assets/media/tariff-features/exchangeNotOffered.svg';

import refundIncluded from '@assets/media/tariff-features/refundIncluded.svg';
import refundAtCharge from '@assets/media/tariff-features/refundAtCharge.svg';
import refundNotOffered from '@assets/media/tariff-features/refundNotOffered.svg';
import IconWithPopover from '../IconWithPopover';
interface WrapperProps {
  isActive?: boolean;
}

const Wrapper = styled.div<WrapperProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (min-width: 768px) {
    justify-content: flex-end;
  }
  @media (max-width: 767px) {
    display: none;
  }
`;

const Price = styled(Text)`
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.main};
`;

const FlagsWrapper = styled.div`
  display: flex;

  & > div {
    margin-right: 8px;
  }

  & > :last-child {
    margin-right: 0;
  }
`;

const AviaFeatures: React.FC = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const { prices, isActive, features } = useTicketProps();

  const newTariffIcons = (
    x: 'Included' | 'NotOffered' | 'AtCharge',
    type: string,
    content: string,
  ) => {
    switch (x) {
      case 'Included':
        if (type === 'Refund') {
          return (
            <IconWithPopover tip={content}>
              {' '}
              <img src={refundIncluded} alt="" height={18} width={18} />{' '}
            </IconWithPopover>
          );
        }
        if (type === 'CarryOn') {
          return (
            <IconWithPopover tip={'Ручная кладь ' + content}>
              {' '}
              <img src={carryOnIncluded} alt="" height={18} width={18} />
            </IconWithPopover>
          );
        }
        if (type === 'Exchange') {
          return (
            <IconWithPopover tip={content}>
              {' '}
              <img src={exchangeIncluded} alt="" height={18} width={18} />
            </IconWithPopover>
          );
        }
        break;
      case 'NotOffered':
        if (type === 'Refund') {
          return (
            <IconWithPopover tip={content}>
              {' '}
              <img src={refundNotOffered} alt="" height={18} width={18} />
            </IconWithPopover>
          );
        }
        if (type === 'CarryOn') {
          return (
            <IconWithPopover tip={'Ручная кладь ' + content}>
              {' '}
              <img src={carryOnNotOffered} alt="" height={18} width={18} />
            </IconWithPopover>
          );
        }
        if (type === 'Exchange') {
          return (
            <IconWithPopover tip={content}>
              {' '}
              <img src={exchangeNotOffered} alt="" height={18} width={18} />
            </IconWithPopover>
          );
        }
        break;
      case 'AtCharge':
        if (type === 'Refund') {
          return (
            <IconWithPopover tip={content}>
              {' '}
              <img src={refundAtCharge} alt="" height={18} width={18} />
            </IconWithPopover>
          );
        }
        if (type === 'CarryOn') {
          return (
            <IconWithPopover tip={'Ручная кладь ' + content}>
              {' '}
              <img src={carryOnAtCharge} alt="" height={18} width={18} />
            </IconWithPopover>
          );
        }
        if (type === 'Exchange') {
          return (
            <IconWithPopover tip={content}>
              <img src={exchangeAtCharge} alt="" height={18} width={18} />
            </IconWithPopover>
          );
        }
        break;
    }
  };

  return (
    <Wrapper isActive={isActive}>
      {isMobile && <Price>{Helper.formatPrice(prices[0])} </Price>}

      {!isMobile && (
        <FlagsWrapper>
          {features.map((feature, index) => {
            return (
              <span key={index} style={{ marginRight: 5 }}>
                {newTariffIcons(
                  feature.applicability as any,
                  feature.type,
                  feature.content,
                )}
              </span>
            );
          })}
        </FlagsWrapper>
      )}
    </Wrapper>
  );
};

export default AviaFeatures;
