import React, { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Text } from '@components/ui';
import { ReactComponent as CrossIcon } from '@assets/media/error-notifications/mobile-cross.svg';
import { useSelector, useDispatch } from 'react-redux';
import { ApplicationState } from '@modules/index';
import {
  createPaymentRequest,
  createPaymentTinkoffRequest,
  getDetailOrder,
  getQrRequest,
} from '@modules/orders';
import russianCard from '@assets/media/order-detail/russianCard.svg';
import sbpPaymentImage from '@assets/media/order-detail/sbpPaymentImage.svg';

import internationalCard from '@assets/media/order-detail/internationalCard.svg';
import { getUserState } from '@modules/user';
import { RoundIcon } from '@components/loader';
import { useHistory } from 'react-router-dom';
import NotifyLoader from '../NotifyLoader';
import Popup from 'reactjs-popup';
import usePaymentResponse from './hooks/usePaymentResponse';
import { Hooks } from '@utils';
import Button from '@components/ui/Button';
import { SOCKET_ORDER_UPDATE } from '@modules/constants';

interface LinkPropsIFrame {
  isMTS: boolean;
  isINT: boolean;
  isHeight: boolean;
  isSBP: boolean;
  isRus?: boolean;
}

interface PaymentMethod {
  label: string;
  amount?: number;
  img: string;
  value?: any;
}

interface LinkProps {
  isActive: boolean;
}

const StyledCloseIcon = styled(CrossIcon)`
  cursor: pointer;
  flex-shrink: 0;
  margin-left: 10px;
`;

const SpbHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const SbpLeftSide = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
`;

const SbpRightSide = styled.div`
  display: flex;
`;

const Wrapper = styled.div`
  position: relative;
  margin-top: 40px;
`;

const ChoosePaymentBlock = styled.div`
  border: 1px solid #dcdcdc;
  border-radius: 8px;
  height: 84px;
  display: flex;
  align-items: center;

  @media screen and (min-width: 320px) and (max-width: 1439px) {
    height: 90px;
    text-align: center;
    width: 100%;
    margin-bottom: 30px;
  }
`;

const ChoosePaymentElement = styled.div<LinkProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0;
  padding: 0 15px;
  cursor: pointer;
  border: 1px solid #dcdcdc;
  border-color: ${({ isActive }) => (isActive ? '#4872F2' : '#dcdcdc')};
  border-radius: ${({ isActive }) => (isActive ? '8px' : '0px')};
  border-right: ${({ isActive }) => (isActive ? '1px solid #4872F2' : '0px')};
  border-left: ${({ isActive }) => (isActive ? '1px solid #4872F2' : '0px')};
  border-top: ${({ isActive }) => (isActive ? '1px solid #4872F2' : '0px')};
  border-bottom: ${({ isActive }) => (isActive ? '1px solid #4872F2' : '0px')};
  background: ${({ isActive }) => (isActive ? '#EDF1FE' : '')};
  height: 84px;
  width: 50%;
  @media screen and (min-width: 320px) and (max-width: 767px) {
    flex-direction: column;
    height: 90px;
    text-align: center;
  }
`;

const Title = styled(Text)`
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 16px;
    display: none;
  }
`;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;

  @media screen and (min-width: 320px) and (max-width: 768px) {
    padding-left: 15px;
  }
`;

const BlockText = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #3c3c3c;
  @media screen and (min-width: 320px) and (max-width: 767px) {
    font-size: 13px;
    margin-left: 15px;
  }
`;

const IframeContainer = styled.div<LinkPropsIFrame>`
  width: auto;
  position: relative;
  overflow: hidden;
  height: ${({ isMTS, isINT, isHeight, isSBP }) =>
    isMTS
      ? '1200px'
      : isINT && !isHeight
        ? '680px'
        : !isINT && isHeight
          ? '350px'
          : isINT && isHeight
            ? '380px'
            : isSBP
              ? '300px'
              : '587px'};

  box-shadow: 5px 5px 10px 5px #0000000d;
  padding: 25px;

  margin-top: 20px;

  @media screen and (min-width: 320px) and (max-width: 768px) {
    height: ${({ isMTS, isINT, isSBP }) =>
      isMTS ? '1150px' : isINT ? '820px' : isSBP ? '350px' : '647px'};
    width: 100%;
    box-sizing: border-box;
    padding: 15px 0;
  }
`;

const Iframe = styled.iframe<LinkPropsIFrame>`
  min-width: 100%;
  border: none;
  height: 100%;
  max-height: ${({ isMTS, isINT, isHeight }) =>
    isMTS
      ? '1200px'
      : isINT && !isHeight
        ? '680px'
        : !isINT && isHeight
          ? '350px'
          : isINT && isHeight
            ? '360px'
            : '537px'};
  @media screen and (max-width: 767px) {
    display: ${({ isRus }) => (isRus ? 'none' : 'flex')};
  }
`;

const IframeLoaderContainer = styled.div`
  position: relative;
  width: 100%;
  height: 300px;
`;

const QRCodeLoaderContainer = styled.div`
  position: relative;
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

const QRCodeText = styled.span`
  position: relative;
  top: 80px;
`;

const PaymentButtonContainer = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FlexAlign = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledPopup = styled(Popup)`
  max-height: 100vh;
  overflow-y: auto;

  &-overlay {
    @media (max-width: 767px) {
      z-index: 1000 !important;
    }
  }

  &-content {
    width: 400px;
    border: 1px solid #dcdcdc;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    padding: 20px;
    box-sizing: border-box;
    max-height: 100%;
    overflow-y: auto;
  }
`;

const PaymentIframe: React.FC<{
  chosenPaymentMethod: string;
  setChosenPaymentMethod: Dispatch<SetStateAction<string>>;
  promocodeStatus?: string | boolean | null;
  isPaymentLoader?: boolean;
}> = ({
  chosenPaymentMethod,
  setChosenPaymentMethod,
  promocodeStatus,
  isPaymentLoader,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isMobile } = Hooks.useMediaSizes();

  const [isProcessing, setIsProcessing] = React.useState<any>(false);
  const [sbpModalIsOpen, setSbpModalIsOpen] = React.useState(false);
  const [qrCodeIsLoading, setQrCodeIsLoading] = React.useState(false);

  const {
    availableActions,
    insurances,
    additionalServices,
    orderPositions,
    orderNumber,
    acquiringCommissions,
  } = useSelector(getDetailOrder);

  const paymentUrl = useSelector(
    (state: ApplicationState) => state.orders.orderDetail?.paymentUrl,
  );
  const paymentTinkoffUrl = useSelector(
    (state: ApplicationState) => state.orders.orderDetail?.paymentTinkoffUrl,
  );

  const paymentQr = useSelector(
    (state: ApplicationState) => state.orders.orderDetail?.paymentQr,
  );

  const paymentTinkoffQrNumber = useSelector(
    (state: ApplicationState) =>
      state.orders.orderDetail?.paymentTinkoffQrNumber,
  );

  const [chosenPaymentImage, setChosenPaymentImage] =
    React.useState(russianCard);

  const orderPositionIds = orderPositions.map((x) => x.id);

  const userState = useSelector(getUserState);

  const { height, isNotifyLoader } = usePaymentResponse({
    orderNumber,
    orderPositionIds,
  });

  const socketProtocol = process.env.REACT_APP_MODE === 'prod' ? 'wss' : 'ws';

  const socket = new WebSocket(
    `${socketProtocol}://${SOCKET_ORDER_UPDATE}/paymentUpdate/ws?number=${paymentTinkoffQrNumber}`,
  );

  const receiveMessageSocket = (event: any) => {
    if (JSON.parse(event.data).IsProcessing === true) {
      if (!window.location.pathname.includes('/orders') && orderNumber !== 0) {
        history.push(`/orders/${orderNumber}`);
        socket.removeEventListener('message', receiveMessageSocket);
      } else if (window.location.pathname.includes('/orders')) {
        window.location.reload();
        socket.removeEventListener('message', receiveMessageSocket);
      }
    }
  };

  socket.addEventListener('message', receiveMessageSocket);

  React.useEffect(() => {
    if (
      chosenPaymentMethod === 'Российская карта' ||
      chosenPaymentMethod === 'Оплатить через СБП'
    ) {
      dispatch(
        createPaymentTinkoffRequest({
          correlationId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
          orderNumber: orderNumber,
          orderPositionIds: orderPositionIds,
          tinkoffSettingType: 0,
        }),
      );
    } else
      dispatch(
        createPaymentRequest({
          correlationId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
          orderNumber: orderNumber,
          orderPositionIds: orderPositionIds,
          gatelineSettingType: 1,
        }),
      );
  }, [additionalServices, insurances, promocodeStatus]);

  const paymentMethods: PaymentMethod[] = [
    {
      label: 'Оплатить через СБП',
      amount: acquiringCommissions?.find((x) => x.paymentType === 'Rus')
        ?.amount,
      img: sbpPaymentImage,
    },
    {
      label: 'Российская карта',
      amount: acquiringCommissions?.find((x) => x.paymentType === 'Rus')
        ?.amount,
      img: russianCard,
      value: createPaymentTinkoffRequest({
        correlationId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
        orderNumber: orderNumber,
        orderPositionIds: orderPositionIds,
        tinkoffSettingType: 0,
      }),
    },
    {
      label: 'Зарубежная карта',
      img: internationalCard,
      amount: acquiringCommissions?.find((x) => x.paymentType === 'Int')
        ?.amount,

      value: createPaymentRequest({
        correlationId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
        orderNumber: orderNumber,
        orderPositionIds: orderPositionIds,
        gatelineSettingType: 1,
      }),
    },
    // {
    //   label: 'Оплата в кредит',
    //   img: creditCard,
    //   value: createPaymentRequest({
    //     correlationId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
    //     orderNumber: orderNumber,
    //     orderPositionIds: orderPositionIds,
    //     gatelineSettingType: 0,
    //   }),
    // },
  ];

  const choosePaymentClick = (item: PaymentMethod) => {
    if (item.label === 'Оплатить через СБП' && paymentTinkoffQrNumber) {
      if (!paymentQr) {
        setQrCodeIsLoading(true);
      }
      dispatch(
        getQrRequest({
          paymentNumber: paymentTinkoffQrNumber,
          tinkoffSettingType: 0,
          tinkoffQrType: isMobile ? 1 : 0,
        }),
      );
      setSbpModalIsOpen(true);
    } else {
      setChosenPaymentMethod(item.label);
      setChosenPaymentImage(item.img);
      if (item?.value) {
        dispatch(item.value);
      }
    }
  };

  useEffect(() => {
    if (paymentQr) {
      setQrCodeIsLoading(false);
    }
  }, [paymentQr]);

  const qrCode = paymentQr && paymentQr.svg;

  const handleCloeSbpModal = () => {
    setSbpModalIsOpen(false);
    setQrCodeIsLoading(false);
  };

  useEffect(() => {
    if (
      paymentUrl &&
      isMobile &&
      chosenPaymentMethod === 'Российская карта' &&
      !document.getElementById('TinkoffIframe')
    ) {
      const script = document.createElement('script');

      const contentContainer = document.getElementById('payment_block');

      script.src = 'https://kassa.cdn-tinkoff.ru/integration/integration.js';
      script.type = 'text/javascript';
      script.async = false;
      document.body.appendChild(script);
      script.onload = () => {
        const element = document.createElement('iframe');
        element.setAttribute('id', 'TinkoffIframe');

        element.setAttribute(
          'src',
          `https://securepayments.tinkoff.ru${new URL(paymentUrl).pathname}`,
        );
        element.setAttribute('id', 'TinkoffIframe');
        element.style.width = '100%';
        element.style.height = '617px';

        element.style.padding = '15px 0';
        element.style.border = '0';

        if (contentContainer) {
          contentContainer.appendChild(element);
        }

        //@ts-ignore
        const paymentForm = new PaymentForm.Integration({
          iframe: { element },
        });
      };
      document.getElementsByTagName('body')[0].appendChild(script);
    }
    if (isMobile && chosenPaymentMethod === 'Зарубежная карта') {
      const element = document.getElementById('TinkoffIframe');
      if (element) {
        element.style.display = 'none';
      }
    }

    if (
      isMobile &&
      chosenPaymentMethod === 'Российская карта' &&
      document.getElementById('TinkoffIframe')
    ) {
      const element = document.getElementById('TinkoffIframe');
      if (element) {
        element.style.display = 'block';
      }
    }
  }, [paymentUrl]);

  return (
    <Wrapper>
      {isNotifyLoader && <NotifyLoader />}

      <>
        <div style={{ marginBottom: 20 }}>
          <Title>Способы оплаты</Title>
        </div>
        {(availableActions.includes('PAYMENT') ||
          availableActions.includes('EXTRAPAYMENT')) &&
          !userState.data?.agentId && (
            <>
              {/* <PaymentHeading id="payment_block">Способы оплаты</PaymentHeading> */}
              {height === '0px' && (
                <ChoosePaymentBlock>
                  {paymentMethods.map((item) => (
                    <>
                      <ChoosePaymentElement
                        isActive={chosenPaymentMethod == item.label}
                        onClick={() => choosePaymentClick(item)}
                      >
                        <img
                          src={item.img}
                          style={{
                            marginRight: 7,
                          }}
                        />{' '}
                        <div> {item.label} </div>
                        <div
                          style={{
                            color: '#4872F2',
                            marginTop: 7,
                            fontWeight: 'bold',
                          }}
                        >
                          {item.label !== 'Оплата в кредит' ? (
                            <>
                              {item.amount
                                ?.toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                              &nbsp;₽
                            </>
                          ) : (
                            <> &nbsp;</>
                          )}
                        </div>
                      </ChoosePaymentElement>
                    </>
                  ))}
                </ChoosePaymentBlock>
              )}

              {paymentUrl && !isPaymentLoader ? (
                <IframeContainer
                  id="payment_block"
                  isRus={chosenPaymentMethod === 'Российская карта'}
                  isHeight={height !== '0px'}
                  isMTS={chosenPaymentMethod === 'Оплата в кредит'}
                  isINT={chosenPaymentMethod === 'Зарубежная карта'}
                  isSBP={chosenPaymentMethod === 'Оплатить через СБП'}
                >
                  <FlexRow>
                    <img
                      src={chosenPaymentImage}
                      style={{
                        marginRight: 7,
                      }}
                    />{' '}
                    {chosenPaymentMethod}&nbsp;
                    <span
                      style={{
                        color: '#4872F2',
                        fontWeight: 'bold',
                      }}
                    >
                      {chosenPaymentMethod === 'Российская карта' ||
                      chosenPaymentMethod === 'Оплатить через СБП'
                        ? acquiringCommissions
                            ?.find((x) => x.paymentType === 'Rus')
                            ?.amount?.toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + ' ₽'
                        : acquiringCommissions
                            ?.find((x) => x.paymentType === 'Int')
                            ?.amount?.toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + ' ₽'}
                    </span>
                  </FlexRow>
                  {chosenPaymentMethod === 'Зарубежная карта' && (
                    <BlockText style={{ marginBottom: 20 }}>
                      Оплата будет произведена в белорусских рублях по текущему
                      курсу к российскому рублю.
                    </BlockText>
                  )}
                  {chosenPaymentMethod === 'Зарубежная карта' && (
                    <BlockText style={{ marginBottom: 20 }}>
                      Включён сбор за оплату зарубежной картой:{' '}
                      <span
                        style={{
                          color: '#4872F2',
                          fontWeight: 600,
                        }}
                      >
                        {' '}
                        {acquiringCommissions[1].acquiringAmount} ₽
                      </span>
                    </BlockText>
                  )}

                  {chosenPaymentMethod !== 'Оплатить через СБП' ? (
                    <Iframe
                      isRus={chosenPaymentMethod === 'Российская карта'}
                      isMTS={chosenPaymentMethod === 'Оплата в кредит'}
                      isINT={chosenPaymentMethod === 'Зарубежная карта'}
                      isHeight={height !== '0px'}
                      isSBP={chosenPaymentMethod === 'Оплатить через СБП'}
                      id="iFrame1"
                      src={
                        paymentUrl
                          ? paymentUrl
                          : paymentTinkoffUrl
                            ? paymentTinkoffUrl
                            : undefined
                      }
                      loading="lazy"
                    ></Iframe>
                  ) : (
                    <>
                      {chosenPaymentMethod === 'Оплатить через СБП' && (
                        <BlockText style={{ marginBottom: 20 }}>
                          Отсканируйте QR-код с помощью своего смартфона или
                          мобильного приложения банка.
                        </BlockText>
                      )}
                      {qrCode && (
                        <FlexAlign>
                          <img
                            src={`data:image/svg+xml;utf8,${encodeURIComponent(
                              qrCode,
                            )}`}
                          />
                        </FlexAlign>
                      )}
                    </>
                  )}
                </IframeContainer>
              ) : (
                <IframeContainer
                  isMTS={chosenPaymentMethod === 'Оплата в кредит'}
                  isINT={chosenPaymentMethod === 'Зарубежная карта'}
                  isHeight={height !== '0px'}
                  isSBP={chosenPaymentMethod === 'Оплатить через СБП'}
                >
                  <FlexRow>
                    <img
                      src={chosenPaymentImage}
                      style={{
                        marginRight: 7,
                        width: 25,
                        height: 25,
                      }}
                    />{' '}
                    {chosenPaymentMethod}
                  </FlexRow>
                  <IframeLoaderContainer>
                    <RoundIcon />
                  </IframeLoaderContainer>
                </IframeContainer>
              )}
            </>
          )}
      </>
      <StyledPopup onClose={handleCloeSbpModal} open={sbpModalIsOpen}>
        {qrCodeIsLoading ? (
          <QRCodeLoaderContainer>
            <RoundIcon />
            {isProcessing && <QRCodeText>Обработка платежа</QRCodeText>}
          </QRCodeLoaderContainer>
        ) : (
          <>
            <SpbHeader>
              <SbpLeftSide>
                <img
                  src={paymentMethods[0].img}
                  style={{
                    marginRight: 7,
                  }}
                />{' '}
                <span>{paymentMethods[0].label}</span>
                <div
                  style={{
                    color: '#4872F2',
                    marginLeft: 7,
                  }}
                >
                  <>
                    {paymentMethods[0].amount
                      ?.toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                    &nbsp;₽
                  </>
                </div>
              </SbpLeftSide>
              <SbpRightSide>
                <StyledCloseIcon onClick={handleCloeSbpModal} />
              </SbpRightSide>
            </SpbHeader>
            {isMobile ? (
              <PaymentButtonContainer>
                <Button
                  type={'primary'}
                  color={'yellow'}
                  onClick={() => {
                    if (qrCode) {
                      window.location.href = qrCode;
                    }
                  }}
                >
                  Оплатить
                </Button>
              </PaymentButtonContainer>
            ) : (
              !!qrCode && (
                <>
                  <BlockText style={{ marginBottom: 20 }}>
                    Отсканируйте QR-код с помощью своего смартфона или
                    мобильного приложения банка. <br /> После успешной оплаты
                    билеты будут направлены вам по электронной почте.
                  </BlockText>
                  <FlexAlign>
                    <img
                      src={`data:image/svg+xml;utf8,${encodeURIComponent(
                        qrCode,
                      )}`}
                    />
                  </FlexAlign>
                </>
              )
            )}
          </>
        )}
      </StyledPopup>
    </Wrapper>
  );
};

export default PaymentIframe;
