import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import Text from '@components/ui/Typography';
import { FlightEntity } from '@modules/booking';
import { ApplicationState } from '@modules/index';
import FlightsList from '@components/booking/YourFlight/FlightsList';
import { Helper } from '@utils';
import moment from 'moment/moment';
import { Train } from '@modules/trainTickets';
import { FLIGHT_TYPES } from '@components/booking/FlightDetail/types';

const Wrapper = styled.div`
  padding: 0 24px;
`;

const Title = styled(Text)`
  @media (max-width: 1024px) {
    font-size: 14px;
    line-height: 18px;
    color: #3c3c3c;
  }
`;

const YourTrip: React.FC = () => {
  const trainForward = useSelector((state: ApplicationState) => state.trainTickets.trainForward);
  const trainBackward = useSelector((state: ApplicationState) => state.trainTickets.trainBack);

  const trainToFlightEntity = (train: Train): FlightEntity => {
    return {
      duration: moment.duration(train.tripDuration).asMinutes(),
      index: train.index,
      minAvailSeats: 0,
      stops: [],
      flight_Type: FLIGHT_TYPES.REGULAR,
      isLowcoster: false,
      transitVisaInfo: null,
      transitVisaInfoText: null,
      time: '',
      segments: [{
        index: train.index,
        fareFamilyFeature: null,
        techLandings: [],
        duration: 0,
        flightNo: '',
        airline: '',
        airplane: '',
        operatingAirlineCode: '',
        airlineCode: '',
        from: {
          code: train.fromStation.name_ru,
          airport: train.fromStation.name_ru,
          city: train.fromStation.city.name_ru,
          country: 'RU',
          terminal: '',
        },
        to: {
          code: train.toStation.name_ru,
          airport: train.toStation.name_ru,
          city: train.toStation.city.name_ru,
          country: 'RU',
          terminal: '',
        },
        id: train.index + "",
        fromDate: moment(train.localDepartureDate).format('YYYY-MM-DD'),
        fromTime: moment(train.localDepartureDate).format('HH:mm:ss'),
        toDate: moment(train.localArrivalDate).format('YYYY-MM-DD'),
        toTime: moment(train.localArrivalDate).format('HH:mm:ss'),
        time: Helper.formatDuration(
          moment.duration(train.tripDuration).asMinutes()
        ),
        flightClass: '',
        flight_Type: FLIGHT_TYPES.REGULAR,
        seatMap: null,
        fareBasis: '',
        availSeats: 0,
        classCode: '',
        fareFamily: null,
        operatingAirline: '',
        isLowcoster: false,
      }]
    }
  }
  const trip: FlightEntity[] = [trainToFlightEntity(trainForward.train)];
  if (trainBackward?.index !== null) {
    trip.push(trainToFlightEntity(trainBackward.train))
  }

  return (
    <Wrapper>
      <Title fontWeight="600" size="big">
        Ваша поездка
      </Title>
      <FlightsList items={trip} />
    </Wrapper>
  );
};

export default YourTrip;
