import { getPluginToken } from '@modules/main';
import { select, call, put } from 'typed-redux-saga';
import moment from 'moment';
import _ from 'lodash';
import {
  getBookingTicketState,
  getSelectedFare,
  addictionalServicesSelector,
  bookingInsuranceSelector,
  getBookingAncillaryServicesState,
  getSeatMapState,
} from '../../selectors';
import { BookFlightPayload, BookingWarnignTypes } from '../../types';
import * as Manager from '../../Manager';
import { createBookingDto } from '../../utils';
import { NavigationService } from '../../../../services';
import {
  simpleSignUpSaga,
  getUserStatusState,
  UserSimpleSignUpBody,
} from '@modules/user';
import { bookingLoader, setAuthPopup } from '../../duck';
import { showBookingWarning } from '@modules/notification';
import * as OrderManager from '@modules/orders/Manager';
import { setDetailData } from '@modules/orders';
import {
  getAvailableInsurancesWorker,
  getNotPaidAddictionalServices,
} from '@modules/orders/sagas';

export function* bookTicket(data: BookFlightPayload) {
  const ticket = yield* select(getBookingTicketState);
  const fare = yield* select(getSelectedFare);
  const addictionalServices = yield* select(addictionalServicesSelector);
  const seatMapState = yield* select(getSeatMapState);
  const insurance = yield* select(bookingInsuranceSelector);
  const ancillaryServices = yield* select(getBookingAncillaryServicesState);
  const pluginToken = yield* select(getPluginToken);
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(decodeURIComponent(queryString));

  const urlParamsfull = new URLSearchParams(window.location.search);
  const agentId = urlParamsfull.get('agentId');
  const salesChannel = urlParams.get('partner') || 'tui';
  const marker = urlParams.get('marker') || null;
  const isInsurancesChecked = insurance.list.offers.some(
    (item) => item.checked
  );

  yield* put(bookingLoader(true));

  const result = yield* call(
    Manager.bookTicket,
    createBookingDto(
      ticket,
      ancillaryServices,
      fare.upsellIndex,
      {
        ...data,
        salesChannel,
        isInsurancesChecked,
        marker,
        fareName: fare.fareFamilies[0].name,
        InsuranceProductIds: _(insurance.list.offers)
          .filter((item) => item.checked)
          .map((item) => item.product.id),
      },
      addictionalServices,
      seatMapState,
      agentId,
      pluginToken,
    )
  );
  yield* put(bookingLoader(false));

  if (result.orderNumber !== 0) {

    yield* call(() => {
      // удаляем все формы в sessionStorage, которые начинаются на "avia-booking-form-"
      for (let i = 0; i < sessionStorage.length; i++) {
        const key = sessionStorage.key(i);
        if (key && key.startsWith('avia-booking-form-')) {
          sessionStorage.removeItem(key);
        }
      }
    });
    yield* call(
      NavigationService.navigate,
      `/bookingDetails/${result.orderNumber}`
    );
  }

  const warning = (result.warnings || []).find(
    (x) =>
      x.type === BookingWarnignTypes.UnconfirmedAncillaryWarning ||
      x.type === BookingWarnignTypes.PriceChanged
  );
  if (warning) {
    yield* put(bookingLoader(false));
    yield* put(showBookingWarning(warning));
  }
  yield* call(getNotPaidAddictionalServices, {
    orderNumber: result.orderNumber,
  });
  const orderData = yield* call(
    OrderManager.getOrderByNumber,
    result.orderNumber
  );
  yield* put(setDetailData(orderData));
  yield* put(bookingLoader(false));
  yield* call(getAvailableInsurancesWorker, orderData.orderPositions[0].id);
}

/**
 * Check for user authorization.
 * If he is already authorized or user not exists, call book function, otherwise show popup with auth.
 * @param data
 */
export function* signUp(data: BookFlightPayload) {
  const urlParams = new URLSearchParams(window.location.search);
  const agentId = urlParams.get('agentId');

  const authData: UserSimpleSignUpBody = {
    firstName: data.passengers[0].name,
    lastName: data.passengers[0].surname,
    patronymic: data.passengers[0].secondName,
    birthDate: moment(data.passengers[0].birthDate).format('YYYY-MM-DD'),
    gender: data.passengers[0].sex === 'm' ? 'Male' : 'Female',
    phone: data.phone,
    email: data.email,
  };

  if (agentId) {
    authData.agentId = agentId;
  }

  const authSuccessful = yield* call(simpleSignUpSaga, authData);

  if (authSuccessful) {
    yield* call(bookTicket, data);
  } else {
    const { error } = yield* select(getUserStatusState);
    if (error) {
      yield* put(setAuthPopup(true));
    }
  }
}
