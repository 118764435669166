import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import {
  FormikPassengerItemEntity,
  getBookingFaresState,
  setPassengerName,
  setPassengerSecondName,
  setPassengerSurname,
  UpdatePassengerPayload,
} from '@modules/booking';
import Text from '@components/ui/Typography';
import { PassengerItemProps } from './types';
import styled from 'styled-components';
import {
  Select,
  DatePicker,
  Field,
  RadioGroup,
  Checkbox,
} from '@components/ui/form';
// import AncilllaryServices from './AncillaryServices';
import { Helper, Hooks } from '@utils';
import { useSelector, useDispatch } from 'react-redux';
import { ApplicationState } from '@modules/index';
import { PassangerAutoSelectModal } from '../AutoSelectModal';
import { getCountrySearchList, getPasList } from '../../../utils/helpers';
import _ from 'lodash';
import moment from 'moment';
import { getPassanagersRequest, getUserState } from '@modules/user';
import NumberTooltip from '@components/aviaPassengers/Form/NumberTooltip';
import InputMask from 'react-input-mask';
import { getPassengerIconPath } from '@components/booking/PassengersBlock/utils';
import { CONVERTED_DOCS } from '../AutoSelectModal/utils';
import { SELECTED_DOC } from '../AutoSelectModal/PassangerAutoSelectModal';
import CountriesSelect from '@components/booking/CountriesSelect';
import cross from '@assets/media/booking/cross.svg';
import { LOGGING_ACTION_IDS } from '@modules/logs/constants';
import { postSessionLogHandler } from '@modules/logs/duck';
import { LOG_ACTION_TYPE } from '@modules/logs/types';
import { FLIGHT_TYPES } from '@components/booking/FlightDetail/types';

//TODO: декомпозировать и упростить компонент

const Wrapper = styled.div`
  position: relative;
`;

const SubLabel = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #737373;
  margin-bottom: 5px;
  position: absolute;
  margin-top: 0;
`;

const Label = styled.label`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #737373;
  margin-bottom: 5px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: contents;
`;

const Header = {
  Wrapper: styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    ${Text} {
      font-weight: bold;
    }

    @media (max-width: 780px) {
      margin-bottom: 20px;
      flex-direction: column;
      justify-content: flex-start;
      align-items: baseline;
      ${Text} {
        font-weight: 600;
      }
    }
  `,
};

const FormBlockNew = styled.div<LinkProps>`
  display: grid;
  grid-template-areas: ${({ isSamo }) =>
    isSamo
      ? `'field-1 field-2 field-3'
  'field-69 . field-4'
  'field-5 field-6 . '
  'field-7 field-8 field-8 '
  'field-9 field-10 field-12  '`
      : `'field-1 field-2 field-3'
  'field-69 . field-4'
  'field-5 field-6 . '
  'field-7 field-8 field-8 '
  ". . ."
  'field-9  field-12 . '
  'field-13  field-14 field-14 '`};
  grid-template-columns: repeat(3, 1fr);
  column-gap: 15px;
  row-gap: 15px;

  .custom-input {
    width: 100%;
  }

  @media (max-width: 1023px) {
    grid-template-columns: 1fr;
    column-gap: 0;
    row-gap: 30px;
    grid-template-areas:
      'field-6'
      'field-1'
      'field-2'
      'field-3'
      'field-4'
      'field-5'
      'field-7'
      'field-8'
      'field-9'
      'field-10'
      'field-12'
      'field-13'
      'field-14';
  }

  & .field-1 {
    grid-area: field-1;
  }

  & .field-2 {
    grid-area: field-2;
  }

  & .field-3 {
    grid-area: field-3;
  }

  & .field-4 {
    grid-area: field-4;
  }

  & .field-5 {
    grid-area: field-5;
  }

  & .field-6 {
    grid-area: field-6;
    @media (max-width: 767px) {
      height: 56px;
    }
  }

  & .field-7 {
    grid-area: field-7;
  }

  & .field-8 {
    grid-area: field-8;
  }

  & .field-9 {
    grid-area: field-9;
  }

  & .field-10 {
    grid-area: field-10;
  }

  & .field-11 {
    grid-area: field-11;
  }

  & .field-12 {
    grid-area: field-12;
  }

  & .field-13 {
    grid-area: field-13;
  }

  & .field-14 {
    grid-area: field-14;
  }

  & .field-69 {
    grid-area: field-69;
  }

  /* & .field-14 {
          grid-area: field-14;
        } */
`;

const StyledField = styled(Field)`
  width: 100%;
  position: relative;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }
`;

const AddPassangers = styled(Text)`
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0;
  text-align: right;
  color: #4872f2;

  &:hover {
    cursor: pointer;
  }

  ${({ theme }) => `
    ${theme.max('780px')} {
      margin-left:0px;
      margin-top: 15px;
    }`}
`;

const LoyalityBlock = styled.div<{ checked: boolean }>`
  display: grid;
  grid-template-columns: 215px 256px;
  column-gap: 15px;
  row-gap: 30px;
  margin-top: 10px;

  @media (max-width: 1023px) {
    margin-top: 15px;
    grid-template-columns: 1fr;
    column-gap: 0;
    row-gap: 20px;
    display: block;
    & > div {
      margin-bottom: 30px;
    }

    & > :last-child {
      margin-bottom: 0;
    }
  }

  & .field-1 {
    grid-area: field-1;
  }

  & .field-2 {
    grid-area: field-2;
  }

  & .field-3 {
    grid-area: field-3;
  }
`;

const PassengerIcon = styled.img`
  width: 30px;
  height: 30px;
  @media (max-width: 767px) {
    width: 25px;
    height: 25px;
  }
`;

const FioBLock = styled.div`
  & > :first-child {
    display: block;
    margin-bottom: 5px;
  }
`;

const AbolutePassengersList = styled.div`
  position: absolute;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0;
  text-align: left;
  color: #3c3c3c;
  display: flex;
  flex-direction: column;
  z-index: 10000;
  max-height: 130px;
  overflow-y: scroll;
  overflow-x: hidden;

  box-shadow: rgba(117, 122, 136, 0.19) 0px 4px -12px 5px;
  width: 100%;
`;

const AbsoluteItem = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  height: 58px;
  cursor: pointer;
  padding: 5px;

  :hover {
    background-color: #f1f4ff;
  }
`;

const AbsoluteSubText = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  color: #737373;
  margin-bottom: 10px;
`;

const MobileDescription = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #737373;
  margin-top: 20px;
  @media (min-width: 768px) {
    display: none;
  }
`;

const CrossRow = styled.div`
  display: flex;
  align-items: center;
`;

const CrossBlock = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 6px;
  background: #edf1fe;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 22px;
  margin-left: 10px;
  cursor: pointer;
`;

const HandleErrorText = styled.div`
  font-size: 11px;
  color: #e73c3e;
  line-height: 16px;
`;

const Cross = styled.img.attrs({ src: cross })``;

interface LinkProps {
  isSamo: boolean;
}

const PassengerItem: React.FC<PassengerItemProps> = ({
  index,
  onChange,
  frequentFlyerAirlines,
  countriesList,
  setIsShowError,
  ...props
}) => {
  const dispatch = useDispatch();
  const { isMobile } = Hooks.useMediaSizes();
  const searchPanelType = useSelector(
    (state: ApplicationState) => state.ui.searchPanelType,
  );
  const fares = useSelector(getBookingFaresState);
  const userState = useSelector(getUserState);
  const [passengersList, setPassengersList] = React.useState([]);
  const [citizenAbsolute, setCitizenAbsolute] = React.useState({
    value: '',
    label: '',
  });
  const [isNumberFocus, setIsNumberFocus] = React.useState(false);

  // const [fakeCitizen, setFakeCitizen] = React.useState('');
  // const [fakeCountryImage, setFakeCountryImage] = React.useState<any>('');
  // const [fakeCountryCode, setFakeCountryCode] = React.useState<any>('');

  // const [isShowCountries, setIsShowCountries] = React.useState(false);
  // const [countryData, setCountryData] = React.useState<any>([]);

  const [isSecondNameRequired, setIsSecondNameRequired] = React.useState(
    props.secondNameRequired,
  );

  const booking = useSelector((state: ApplicationState) => state.booking);
  const ticket = booking.ticket;
  const flights = ticket.flights;
  const isInternationalFlight = ticket.isInternationalFlight;
  const isDomesticPassportFlight = ticket.isDomesticPassportFlight;

  let airlines: any[] = [];
  let notSamo = true;
  const isAvia = searchPanelType === 'avia';

  const oneOfFlightsIsCharter = flights
    ?.map((flight: any) =>
      flight?.segments?.find(
        (segment: any) => segment?.flight_Type === FLIGHT_TYPES.CHARTER,
      ),
    )
    .filter(Boolean);

  const formik = useFormikContext<{
    passengers: FormikPassengerItemEntity[];
  }>();
  if (isAvia) {
    notSamo =
      fares?.items?.some((x) => x.gds !== 'SAMO') &&
      userState?.data?.agentGroup !== 'Самотур (GDS тур)';

    airlines = React.useMemo(
      () =>
        fares.items
          .map((x) =>
            x.groups
              ?.map((y) =>
                y.flights
                  .map((z) =>
                    z.segments.map((h) => ({
                      value: h.airlineCode,
                      label: h.airline,
                    })),
                  )
                  .flat(),
              )
              .flat(),
          )
          .flat(),

      [frequentFlyerAirlines?.length],
    )
      .flat()
      .filter(Boolean);
  }

  const airlinesCombined = airlines.reduce((op, inp) => {
    op[inp.value] = inp;
    return op;
  }, {});

  const handleLogging = (
    eventType: LOG_ACTION_TYPE,
    actionId: string,
    value?: unknown,
  ) => {
    dispatch(
      postSessionLogHandler({
        eventType: eventType,
        screen: LOGGING_ACTION_IDS.AVIA_BOOKING_FIRST_STEP.NAME,
        actionId: actionId,
        value: value,
      }),
    );
  };

  const handleTicketClassChange = (value: string) => {
    formik.setFieldValue(`passengers.${index}.sex`, value);
    handleLogging(
      'CHANGE',
      LOGGING_ACTION_IDS.AVIA_BOOKING_FIRST_STEP.PASSENGER_GENDER_CHECKBOX,
      value,
    );
  };

  const [isLoyalVisibile, setLoyalVisibility] = React.useState(false);

  const errors =
    (formik.errors.passengers as
      | undefined
      | {
          birthDate: string;
          name: string;
          nameEng: string;
          surname: string;
          surnameEng: string;
          secondName: string;
          secondNameEng: string;
          number: string;
          documentInfo: string;
          documentSeries: string;
          dateOfIssue: string;
          sex: string;
          documentType: string;
          citizenship: string;
          type: {
            id: string;
            description: string;
          };
        }[]) || [];
  const error = errors[index]
    ? errors[index]
    : ({} as {
        birthDate: string;
        name: string;
        nameEng: string;
        surname: string;
        surnameEng: string;
        secondName: string;
        secondNameEng: string;
        number: string;
        documentInfo: string;
        documentSeries: string;

        dateOfIssue: string;
        sex: string;
        documentType: string;
        citizenship: string;
        type: {
          id: string;
          description: string;
        };
      });
  const { touched, setFieldTouched } = formik;

  const isTouched =
    touched.passengers && touched.passengers[index]
      ? touched.passengers[index]
      : ({} as FormikPassengerItemEntity);

  const DocumentType = React.useMemo(() => {
    switch (props.ageCategory) {
      case 'ADULT': {
        let arr = [
          { value: 'PASSPORT', label: 'Паспорт РФ' },
          { value: 'INTERNATIONAL_PASSPORT1', label: 'Заграничный паспорт' },
          { value: 'BIRTH_CERTIFICATE', label: 'Свидетельство о рождении' },
          { value: 'NATIONALPASSPORT', label: 'Нац. паспорт' },
          { value: 'INTERNATIONAL_PASSPORT', label: 'Другой документ' },
        ];
        // if (
        //   props.birthDate &&
        //   moment().diff(moment(props.birthDate), 'year') >= 12 &&
        //   moment().diff(moment(props.birthDate), 'year') <= 14
        // ) {
        //   arr.push({
        //     value: 'BIRTH_CERTIFICATE',
        //     label: 'Свидетельство о рождении',
        //   });
        // }

        if (isInternationalFlight && !isDomesticPassportFlight) {
          arr = arr.filter((val) => val.value !== 'PASSPORT');
        }
        if (isInternationalFlight) {
          arr = arr.filter((val) => val.value !== 'BIRTH_CERTIFICATE');
        }
        if (props.citizenship === 'RU') {
          arr = arr.filter((val) => val.value !== 'NATIONALPASSPORT');
        }
        if (props.citizenship !== 'RU') {
          arr = arr.filter((val) => val.value !== 'INTERNATIONAL_PASSPORT1');
          arr = arr.filter((val) => val.value !== 'PASSPORT');
        }
        return arr;
      }
      default: {
        const arr = [
          { value: 'BIRTH_CERTIFICATE', label: 'Свидетельство о рождении' },
          { value: 'INTERNATIONAL_PASSPORT1', label: 'Заграничный паспорт' },
        ];
        if (props.citizenship !== 'RU' || isInternationalFlight) {
          arr.push({ value: 'NATIONALPASSPORT', label: 'Нац. паспорт' });
          arr.push({
            value: 'INTERNATIONAL_PASSPORT',
            label: 'Другой документ',
          });
          arr.splice(0, 1);

          // arr.filter((val) => val.value !== 'PASSPORT');
        }
        return arr;
      }
    }
  }, [props.ageCategory, props.birthDate, props.citizenship]);

  const [{ documentType, citizenship }, setSelectVal] = useState({
    documentType: DocumentType[0],
    citizenship: { value: 'RU', label: 'Россия' },
  });

  useEffect(() => {
    if (documentType === undefined) {
      setSelectVal((v) => ({ ...v, documentType: DocumentType[0] }));
    }
  }, [documentType]);

  useEffect(() => {
    if (citizenship.value === 'RU') {
      formik.setFieldValue(
        `passengers.${index}.documentType`,
        documentType?.value,
      );
      if (documentType.value === 'NATIONALPASSPORT') {
        formik.setFieldValue(
          `passengers.${index}.documentType`,
          'INTERNATIONAL_PASSPORT1',
        );
        setSelectVal(() => ({ citizenship, documentType: DocumentType[0] }));
      }
    } else {
      formik.setFieldValue(
        `passengers.${index}.documentType`,
        'INTERNATIONAL_PASSPORT1',
      );
    }

    formik.setFieldValue(`passengers.${index}.citizenship`, citizenship?.value);
    if (citizenship?.value !== 'RU') {
      setSelectVal(() => ({ citizenship, documentType: DocumentType[0] }));
    }
  }, [DocumentType, props.citizenship]);

  const handleBirthDateChange = (val: Date | null | any) => {
    if (val.getMonth) {
      formik.setFieldValue(`passengers.${index}.birthDate`, val);
    } else {
      formik.setFieldValue(`passengers.${index}.birthDate`, val);

      const date = val;
      const [day, month, year] = date.split('.');
      const newDate = new Date(+year, +month - 1, +day);
      // const today = new Date();
      if (moment(newDate).isValid()) {
        formik.setFieldValue(`passengers.${index}.birthDate`, newDate);
      }
    }
    handleLogging(
      'CHANGE',
      LOGGING_ACTION_IDS.AVIA_BOOKING_FIRST_STEP.PASSENGER_BIRTHDAY_INPUT,
      val,
    );
  };
  const setPassengerNameDebounced = React.useMemo(
    () =>
      _.debounce((args: UpdatePassengerPayload) => {
        formik.setFieldValue(
          `passengers.${args.passengerIndex}.nameEng`,
          notSamo
            ? Helper.translateToLatin(args.value).toLocaleUpperCase()
            : Helper.translateToLatinSamo(args.value).toLocaleUpperCase(),
        );
        dispatch(setPassengerName(args));
        handleLogging(
          'CHANGE',
          LOGGING_ACTION_IDS.AVIA_BOOKING_FIRST_STEP.PASSENGER_NAME_INPUT,
          args.value,
        );
      }, 400),
    [],
  );

  const setPassengerSurnameDebounced = React.useMemo(
    () =>
      _.debounce((args: UpdatePassengerPayload) => {
        formik.setFieldValue(
          `passengers.${args.passengerIndex}.surnameEng`,
          notSamo
            ? Helper.translateToLatin(args.value).toLocaleUpperCase()
            : Helper.translateToLatinSamo(args.value).toLocaleUpperCase(),
        );
        dispatch(setPassengerSurname(args));
        handleLogging(
          'CHANGE',
          LOGGING_ACTION_IDS.AVIA_BOOKING_FIRST_STEP.PASSENGER_SURNAME_INPUT,
          args.value,
        );
      }, 400),
    [],
  );

  const setPassengerSecondNameDebounced = React.useMemo(
    () =>
      _.debounce((args: UpdatePassengerPayload) => {
        formik.setFieldValue(
          `passengers.${args.passengerIndex}.secondNameEng`,
          notSamo
            ? Helper.translateToLatin(args.value).toLocaleUpperCase()
            : Helper.translateToLatinSamo(args.value).toLocaleUpperCase(),
        );
        dispatch(setPassengerSecondName(args));
        handleLogging(
          'CHANGE',
          LOGGING_ACTION_IDS.AVIA_BOOKING_FIRST_STEP
            .PASSENGER_SECOND_NAME_INPUT,
          args.value,
        );
      }, 400),
    [],
  );

  const handleNameChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const trimValue = !isInternationalFlight
      ? e.target.value.trim()
      : e.target.value.trim().replace(/[^A-Za-z]/gi, '');
    setPassengerNameDebounced({ passengerIndex: index, value: trimValue });
    if (oneOfFlightsIsCharter.length !== 0 && !isInternationalFlight) {
      formik.setFieldValue(`passengers.${index}.name`, trimValue);
    } else {
      formik.setFieldValue(
        `passengers.${index}.name`,
        Helper.translateToLatin(trimValue).toLocaleUpperCase(),
      );
    }
  };

  const pasList = async (str: string) => {
    const response = await getPasList(str);
    setPassengersList(
      response
        ? response.map((pas: any) => ({
            value: pas,
            label: pas.lastName + ' ' + pas.firstName + ' ' + pas.patronymic,
          }))
        : [],
    );
  };

  const handleSurnameChange: React.ChangeEventHandler<HTMLInputElement> = (
    e,
  ) => {
    const trimValue = !isInternationalFlight
      ? e.target.value.trim()
      : e.target.value.trim().replace(/[^A-Za-z]/gi, '');
    if (trimValue !== '') {
      pasList(trimValue);
    }
    if (trimValue === '') {
      setPassengersList([]);
    }
    setPassengerSurnameDebounced({
      passengerIndex: index,
      value: trimValue,
    });
    if (oneOfFlightsIsCharter.length !== 0 && !isInternationalFlight) {
      formik.setFieldValue(`passengers.${index}.surname`, trimValue);
    } else {
      formik.setFieldValue(
        `passengers.${index}.surname`,
        Helper.translateToLatin(trimValue).toLocaleUpperCase(),
      );
    }
  };

  const handleSecondNameChange: React.ChangeEventHandler<HTMLInputElement> = (
    e,
  ) => {
    const trimValue = !isInternationalFlight
      ? e.target.value.trim()
      : e.target.value.trim().replace(/[^A-Za-z]/gi, '');
    setPassengerSecondNameDebounced({
      passengerIndex: index,
      value: trimValue,
    });

    if (oneOfFlightsIsCharter.length !== 0 && !isInternationalFlight) {
      formik.setFieldValue(`passengers.${index}.secondName`, trimValue);
    } else {
      formik.setFieldValue(
        `passengers.${index}.secondName`,
        Helper.translateToLatin(trimValue).toLocaleUpperCase(),
      );
    }
  };

  const handledateOfIssue = (val: Date | null | any) => {
    if (val.getMonth) {
      formik.setFieldValue(`passengers.${index}.dateOfIssue`, val);
    } else {
      formik.setFieldValue(`passengers.${index}.dateOfIssue`, val);

      const date = val;
      const [day, month, year] = date.split('.');
      const newDate = new Date(+year, +month - 1, +day);
      if (moment(newDate).isValid()) {
        formik.setFieldValue(`passengers.${index}.dateOfIssue`, newDate);
      }
    }
  };

  const handleBlur: React.FocusEventHandler<HTMLInputElement> = (e) => {
    const target = e.target;
    setFieldTouched(`passengers.${index}.${target.name}`, true, true);
  };

  const handleBlurDocumentInfo = () => {
    formik.setFieldTouched(`passengers.${index}.documentInfo`, true);
    formik.validateField(`passengers.${index}.documentInfo`);
  };

  const handleBlurDocumentSeries = () => {
    formik.setFieldTouched(`passengers.${index}.documentSeries`, true);
    formik.validateField(`passengers.${index}.documentSeries`);
  };

  const handleDocumentTypeChange = (val: any) => {
    setSelectVal((select) => ({ ...select, documentType: val }));
    formik.setFieldValue(`passengers.${index}.documentType`, val.value);
    if (!notSamo) {
      formik.setFieldValue(
        `passengers.${index}.documentSeries`,
        val.value === 'PASSPORT'
          ? modalData?.number?.replace(/[^A-Za-z0-9]/g, '').substring(0, 4)
          : val.value === 'INTERNATIONAL_PASSPORT1' &&
              modalData?.citizen?.value === 'RU'
            ? modalData?.number?.replace(/[^A-Za-z0-9]/g, '').substring(0, 2)
            : val.value === 'BIRTH_CERTIFICATE' &&
                modalData?.citizen?.value === 'RU'
              ? modalData?.number
                  .replace(/[^A-Za-z0-9]/g, '')
                  .substring(0, props?.number?.length - 6)
              : '',
      );

      formik.setFieldValue(
        `passengers.${index}.documentInfo`,
        val.value === 'PASSPORT'
          ? modalData?.number?.replace(/[^A-Za-z0-9]/g, '').substring(4)
          : val.value === 'INTERNATIONAL_PASSPORT1' &&
              modalData?.citizen?.value === 'RU'
            ? modalData?.number?.replace(/[^A-Za-z0-9]/g, '').substring(2)
            : val.value === 'BIRTH_CERTIFICATE' &&
                modalData?.citizen?.value === 'RU'
              ? modalData?.number?.replace(/[^A-Za-z0-9]/g, '').slice(-6)
              : modalData?.number,
      );
    }

    handleLogging(
      'CHANGE',
      LOGGING_ACTION_IDS.AVIA_BOOKING_FIRST_STEP.PASSENGER_DOCUMENT_SELECT,
      val,
    );
    return;
  };

  const handleChangeDocumentNumber = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    formik.setFieldValue(`passengers.${index}.number`, event.target.value);
    handleLogging(
      'CHANGE',
      LOGGING_ACTION_IDS.AVIA_BOOKING_FIRST_STEP
        .PASSENGER_DOCUMENT_NUMBER_INPUT,
      event.target.value,
    );
  };

  const handleAviacompanyChange = (val: any, i: number) => {
    formik.setFieldValue(
      `passengers.${index}.loyality.${i}.code`,
      val.value.toString(),
    );
    handleLogging(
      'CHANGE',
      LOGGING_ACTION_IDS.AVIA_BOOKING_FIRST_STEP
        .PASSENGER_LOYALTY_CARD_COMPANY_SELECT,
      val.value.toString(),
    );
    return;
  };

  const handleCitizenshipChange = (val: any) => {
    setSelectVal((select) => ({ ...select, citizenship: val }));
    formik.setFieldValue(`passengers.${index}.citizenship`, val.value);
    if (val.value !== 'RU') {
      formik.setFieldValue(
        `passengers.${index}.documentType`,
        'INTERNATIONAL_PASSPORT1',
      );
    }
    handleLogging(
      'CHANGE',
      LOGGING_ACTION_IDS.AVIA_BOOKING_FIRST_STEP.PASSENGER_CITIZENSHIP_SELECT,
      val.label,
    );
    return;
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (
      !isInternationalFlight ||
      (isInternationalFlight && citizenship.value !== 'RU')
    ) {
      formik.setFieldValue(
        `passengers.${index}.secondNameRequired`,
        !e.target.checked,
      );
    }
    if (e.target.checked) {
      formik.setFieldValue(`passengers.${index}.secondName`, '');
      formik.setFieldValue(`passengers.${index}.secondNameEng`, '');
    }
    handleLogging(
      'CHANGE',
      LOGGING_ACTION_IDS.AVIA_BOOKING_FIRST_STEP
        .PASSENGER_NO_SECOND_NAME_CHECKBOX,
      e.target.checked,
    );
  };

  const handleChangeLoyalInput = (
    e: React.ChangeEvent<HTMLInputElement>,
    i: number,
  ) => {
    const value = e.target.value;

    handleLogging(
      'CHANGE',
      LOGGING_ACTION_IDS.AVIA_BOOKING_FIRST_STEP
        .PASSENGER_LOYALTY_CARD_NUMBER_INPUT,
      value,
    );

    formik.setFieldValue(
      `passengers.${index}.loyality.${i}.value`,
      value.toString(),
    );
  };

  const documentMask = React.useMemo(() => {
    switch (documentType?.value) {
      case 'PASSPORT':
        return props.citizenship === 'RU' ? '9999-999999' : '';

      case 'INTERNATIONAL_PASSPORT1':
        return props.citizenship === 'RU' ? '99 9999999' : '';

      // case 'BIRTH_CERTIFICATE':
      //   return props.citizenship === 'RU' ? 'aaa-aa 999999' : '';

      default:
        return '';
    }
  }, [documentType?.value, props.citizenship]);

  const documentMaskCharterSeries = React.useMemo(() => {
    switch (documentType?.value) {
      case 'PASSPORT':
        return props.citizenship === 'RU' ? '9999' : '';

      case 'INTERNATIONAL_PASSPORT1':
        return props.citizenship === 'RU' ? '99' : '';

      // case 'BIRTH_CERTIFICATE':
      //   return props.citizenship === 'RU' ? 'aaa-aa 999999' : '';

      default:
        return '';
    }
  }, [documentType?.value, props.citizenship]);

  const documentMaskCharterNumber = React.useMemo(() => {
    switch (documentType?.value) {
      case 'PASSPORT':
        return props.citizenship === 'RU' ? '999999' : '';

      case 'INTERNATIONAL_PASSPORT1':
        return props.citizenship === 'RU' ? '9999999' : '';

      // case 'BIRTH_CERTIFICATE':
      //   return props.citizenship === 'RU' ? 'aaa-aa 999999' : '';

      default:
        return '';
    }
  }, [documentType?.value, props.citizenship]);

  const numberPlaceholder = React.useMemo(() => {
    if (
      documentType?.value === 'BIRTH_CERTIFICATE' &&
      citizenship?.value === 'RU'
    ) {
      return 'XII-AA 000000';
    } else if (documentType?.value === 'PASSPORT') {
      return '9999-999999';
    } else if (documentType?.value === 'INTERNATIONAL_PASSPORT1') {
      return '99 9999999';
    } else {
      return isMobile ? undefined : 'Номер';
    }
  }, [isMobile, documentType?.value, citizenship]);

  //Auto select

  const [isModalOpen, setisModalOpen] = React.useState(false);
  const [modalData, setModalData] = React.useState<any>(null);

  const isAuthorized = useSelector(
    (state: ApplicationState) => state.user.status.isAuthorized,
  );

  const openAutoSelect = () => {
    handleLogging(
      'CLICK',
      LOGGING_ACTION_IDS.AVIA_BOOKING_FIRST_STEP.ADD_FROM_PASSENGERS_BTN,
    );
    setisModalOpen(true);
    setModalData(null);
  };
  const closeAutoSelect = () => {
    setisModalOpen(false);
  };
  const [autoSelectInProgress, setProgress] = React.useState(false);

  React.useEffect(() => {
    if (isAuthorized) {
      dispatch(getPassanagersRequest({ SearchText: '' }));
    }
  }, [isAuthorized]);

  const passengers = useSelector(
    (state: ApplicationState) => state.user.aviaPassengers,
  );

  const filteredPassengers = React.useMemo(() => {
    if (passengers.length) {
      const createDif = (date: string | Date) => {
        const given = moment(date);
        const current = moment().startOf('day');
        return moment.duration(current.diff(given)).asYears();
      };

      if (props.ageCategory === 'ADULT') {
        return passengers.filter((x) => {
          const diff = createDif(x.birthDate);

          return diff >= 12 ? true : false;
        });
      } else if (props.ageCategory === 'CHILD') {
        return passengers.filter((x) => {
          const diff = createDif(x.birthDate);

          return diff < 12 && diff > 2 ? true : false;
        });
      } else {
        return passengers.filter((x) => {
          const diff = createDif(x.birthDate);

          return diff < 2 ? true : false;
        });
      }
    }

    return [];
  }, [passengers, props.ageCategory]);

  React.useEffect(() => {
    if (modalData) {
      const {
        documentType,
        lastName,
        name,
        secondName,
        number,
        dateOfIssue,
        // dateOfIssueRequired,
        birhDate,
        sex,
        secondNameRequired,
        customerPassengerId,
      } = modalData;

      if (
        isInternationalFlight &&
        (documentType?.value === 'PASSPORT' ||
          documentType?.value === 'BIRTH_CERTIFICATE')
      ) {
        setSelectVal((select) => ({
          ...select,
          citizenship: citizenAbsolute,
          documentType: {
            value: 'INTERNATIONAL_PASSPORT1',
            label: 'Заграничный паспорт',
          },
        }));
      } else {
        setSelectVal((select) => ({
          ...select,
          citizenship: citizenAbsolute,
          documentType,
        }));
      }

      if (
        isInternationalFlight &&
        (documentType?.value === 'PASSPORT' ||
          documentType?.value === 'BIRTH_CERTIFICATE')
      ) {
        formik.setFieldValue(
          `passengers.${index}.documentType`,
          'INTERNATIONAL_PASSPORT1',
        );
      } else {
        formik.setFieldValue(
          `passengers.${index}.documentType`,
          documentType?.value,
        );
      }

      formik.setFieldValue(
        `passengers.${index}.citizenship`,
        citizenAbsolute ? citizenAbsolute?.value : 'RU',
      );
      formik.setFieldValue(`passengers.${index}.birthDate`, birhDate);
      formik.setFieldValue(`passengers.${index}.surname`, lastName);
      if (
        isInternationalFlight &&
        (documentType?.value === 'PASSPORT' ||
          documentType?.value === 'BIRTH_CERTIFICATE')
      ) {
        formik.setFieldValue(`passengers.${index}.number`, '');
      } else {
        formik.setFieldValue(`passengers.${index}.number`, number);
      }

      if (oneOfFlightsIsCharter?.length !== 0 && isInternationalFlight) {
        formik.setFieldValue(`passengers.${index}.name`, name);
        formik.setFieldValue(`passengers.${index}.nameEng`, name);
      } else if (
        oneOfFlightsIsCharter?.length === 0 ||
        (oneOfFlightsIsCharter?.length !== 0 && !isInternationalFlight)
      ) {
        formik.setFieldValue(`passengers.${index}.name`, name);
        if (isSecondNameRequired && !isInternationalFlight) {
          formik.setFieldValue(`passengers.${index}.secondName`, secondName);
          formik.setFieldValue(`passengers.${index}.secondNameEng`, secondName);
        }
        formik.setFieldValue(`passengers.${index}.nameEng`, name);
      }
      formik.setFieldValue(`passengers.${index}.surnameEng`, lastName);
      formik.setFieldValue(`passengers.${index}.dateOfIssue`, dateOfIssue);
      formik.setFieldValue(`passengers.${index}.sex`, sex);
      formik.setFieldValue(
        `passengers.${index}.customerPassengerId`,
        customerPassengerId,
      );
      if (!notSamo) {
        formik.setFieldValue(
          `passengers.${index}.documentSeries`,
          documentType?.value === 'PASSPORT'
            ? number.replace(/[^A-Za-z0-9]/g, '').substring(0, 4)
            : documentType?.value === 'INTERNATIONAL_PASSPORT1' &&
                citizenAbsolute?.value === 'RU'
              ? number.replace(/[^A-Za-z0-9]/g, '').substring(0, 2)
              : documentType?.value === 'BIRTH_CERTIFICATE' &&
                  citizenAbsolute?.value === 'RU'
                ? number
                    .replace(/[^A-Za-z0-9]/g, '')
                    .substring(0, props.number.length - 6)
                : '',
        );

        formik.setFieldValue(
          `passengers.${index}.documentInfo`,
          documentType?.value === 'PASSPORT'
            ? number.replace(/[^A-Za-z0-9]/g, '').substring(4)
            : documentType?.value === 'INTERNATIONAL_PASSPORT1' &&
                citizenAbsolute?.value === 'RU'
              ? number.replace(/[^A-Za-z0-9]/g, '').substring(2)
              : documentType?.value === 'BIRTH_CERTIFICATE' &&
                  citizenAbsolute?.value === 'RU'
                ? number.replace(/[^A-Za-z0-9]/g, '').slice(-6)
                : number,
        );
      }

      if (oneOfFlightsIsCharter?.length === 0) {
        formik.setFieldValue(
          `passengers.${index}.secondNameRequired`,
          secondNameRequired,
        );
      }

      // formik.setFieldValue(
      //   `passengers.${index}.dateOfIssueRequired`,
      //   dateOfIssueRequired
      // );

      closeAutoSelect();
      setProgress(false);
    }
  }, [modalData, citizenAbsolute]);

  const handleBlurName = () => {
    formik.setFieldTouched(`passengers.${index}.name`, false);
  };

  const handleBlurSurname = () => {
    formik.setFieldTouched(`passengers.${index}.surname`, false);
  };

  const handleBlurSecondName = () => {
    formik.setFieldTouched(`passengers.${index}.secondName`, false);
  };

  const documentPassengersList = passengersList
    .map((x: any) =>
      x.value.documents.map((doc: any) => ({ value: x.value, doc: doc })),
    )
    .flat();

  const transformData = (passengerId?: string, docType?: string) => {
    setProgress(true);
    const passenger: any = passengers.find(
      (x: any) => x.customerPassengerId === passengerId,
    );
    const document = passenger?.documents.find((x: any) => x?.id === docType);

    const getCountry = async () => {
      const countries = await getCountrySearchList(
        document?.citizenship || 'Россия',
      );
      if (countries.length) {
        const [a] = countries;
        setCitizenAbsolute(a);
      }
    };

    getCountry();

    const documentType = CONVERTED_DOCS[document?.type];

    const lastName = passenger.lastName;
    const name = passenger.firstName;
    const secondName = passenger.patronymic;
    const number = document?.number;
    const dateOfIssue = document?.issueDate
      ? new Date(document?.issueDate)
      : null;
    const birhDate = new Date(passenger.birthDate);
    const sex = passenger.gender === 'MALE' ? 'm' : 'f';
    const secondNameRequired =
      passenger?.patronymic && !isInternationalFlight ? true : false;
    const customerPassengerId = passenger?.customerPassengerId;

    setModalData({
      documentType,
      lastName,
      name,
      secondName,
      number,
      dateOfIssue,
      birhDate,
      sex,
      secondNameRequired,
      citizenAbsolute,
      customerPassengerId,
    });
  };

  const onClickOutsideListener = () => {
    setPassengersList([]);
    // setIsShowCountries(false);
    // setCountryData([]);
    document.removeEventListener('click', onClickOutsideListener);
  };

  React.useEffect(() => {
    if (oneOfFlightsIsCharter?.length !== 0 || isInternationalFlight) {
      setIsSecondNameRequired(false);
    }
  }, []);

  React.useEffect(() => {
    if (isInternationalFlight && citizenship.value === 'RU') {
      setIsSecondNameRequired(false);
      formik.setFieldValue(`passengers.${index}.secondNameRequired`, false);
    }
  }, [citizenship]);

  React.useEffect(() => {
    if (
      setIsShowError &&
      formik.values.passengers
        .filter((_, i) => i !== 0)
        .some(
          ({ number }) =>
            number.replace(/\D/g, '') ===
              formik.values.passengers[0].number.replace(/\D/g, '') &&
            number !== '',
        )
    ) {
      setIsShowError(true);
    } else {
      setIsShowError && setIsShowError(false);
    }
  }, [formik, isTouched]);

  return (
    <Wrapper id={`passenger.${index}`} key={props.uid}>
      <MobileDescription>
        {isAvia ? (
          <>
            {' '}
            Заполните данные пассажиров. После оплаты внесение изменений
            платное.
          </>
        ) : (
          <>
            Чтобы избежать трудностей при посадке на поезд, внимательно
            заполните данные на каждого пассажира в точности так, как в
            документе, по которому поедете.
          </>
        )}
      </MobileDescription>
      {isAuthorized && filteredPassengers.length > 0 && (
        <AddPassangers onClick={() => openAutoSelect()}>
          + Добавить из моих пассажиров
        </AddPassangers>
      )}
      <Header.Wrapper>
        <Text size="normal" color="black">
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginTop: isAuthorized ? 25 : 15,
            }}
          >
            <PassengerIcon
              src={getPassengerIconPath(props.ageCategory)}
              alt=""
            />
            <span style={{ marginLeft: 8, marginRight: 8 }}>
              Пассажир{' '}
              {`${index + 1}, ${props.ageCategoryDescription.toLowerCase()}`}
            </span>
          </div>
        </Text>
      </Header.Wrapper>
      <FormBlockNew isSamo={!notSamo}>
        <FioBLock className="field-1">
          <StyledField
            data-cy="lastNameRu"
            wrapperClassName="custom-input"
            error={
              error.surname && isTouched.surname ? error.surname : undefined
            }
            onFocus={() =>
              formik.setFieldTouched(`passengers.${index}.surname`, true)
            }
            onChange={handleSurnameChange}
            onBlur={handleBlurSurname}
            label={isInternationalFlight ? 'Фамилия (на латинице)' : 'Фамилия'}
            autoComplete="anyrandomstring1"
            value={props.surname}
            onMouseLeave={() => {
              document.addEventListener('click', onClickOutsideListener);
            }}
          />
          {isMobile &&
            !error.surname &&
            isTouched.surname &&
            !isInternationalFlight &&
            oneOfFlightsIsCharter?.length === 0 && (
              <SubLabel style={{ fontSize: 12, color: '#737373' }}>
                Мы переведем ФИО на латиницу
              </SubLabel>
            )}

          {!isMobile &&
            !isInternationalFlight &&
            oneOfFlightsIsCharter?.length === 0 && (
              <div className="field-69" style={{ position: 'relative' }}>
                <SubLabel
                  style={{ fontSize: 12, color: '#737373', marginTop: 6 }}
                >
                  Мы переведем ФИО на латиницу
                </SubLabel>
              </div>
            )}

          {documentPassengersList && (
            <AbolutePassengersList>
              {documentPassengersList.map((item, index) => (
                <AbsoluteItem
                  key={index}
                  onClick={() => {
                    transformData(item.value.customerPassengerId, item.doc.id);
                    setPassengersList([]);
                  }}
                >
                  <div style={{ marginBottom: 10 }}>
                    {item.value.firstName +
                      ' ' +
                      item.value.lastName +
                      ' ' +
                      item.value.patronymic}
                  </div>
                  <AbsoluteSubText>
                    {SELECTED_DOC[item.doc.type].label + ' ' + item.doc.number}
                  </AbsoluteSubText>
                </AbsoluteItem>
              ))}
            </AbolutePassengersList>
          )}
        </FioBLock>
        <FioBLock className="field-2">
          <StyledField
            data-cy="nameRu"
            wrapperClassName="custom-input"
            error={error.name && isTouched.name ? error.name : undefined}
            onChange={handleNameChange}
            onBlur={handleBlurName}
            onFocus={() =>
              formik.setFieldTouched(`passengers.${index}.name`, true)
            }
            label={isInternationalFlight ? 'Имя (на латинице)' : 'Имя'}
            autoComplete="anyrandomstring2"
            value={props.name}
          />
          {isMobile &&
            !error.name &&
            isTouched.name &&
            !isInternationalFlight &&
            oneOfFlightsIsCharter?.length === 0 && (
              <SubLabel style={{ fontSize: 12, color: '#737373' }}>
                Мы переведем ФИО на латиницу
              </SubLabel>
            )}
        </FioBLock>
        {!(
          (oneOfFlightsIsCharter?.length !== 0 && isInternationalFlight) ||
          (isInternationalFlight &&
            citizenship.value === 'RU' &&
            documentType?.value === 'INTERNATIONAL_PASSPORT1')
        ) && (
          <FioBLock className="field-3">
            <StyledField
              data-cy="patronymicNameRu"
              wrapperClassName="custom-input"
              error={
                error.secondName &&
                isTouched.secondName &&
                props.secondNameRequired
                  ? error.secondName
                  : undefined
              }
              disabled={
                !props.secondNameRequired ||
                (oneOfFlightsIsCharter?.length !== 0 && isInternationalFlight)
              }
              onFocus={() =>
                formik.setFieldTouched(`passengers.${index}.secondName`, true)
              }
              onChange={handleSecondNameChange}
              onBlur={handleBlurSecondName}
              label={
                isInternationalFlight ? 'Отчество (на латинице)' : 'Отчество'
              }
              autoComplete="anyrandomstring3"
              value={
                oneOfFlightsIsCharter?.length !== 0 && isInternationalFlight
                  ? ''
                  : props.secondName
              }
            />
            {isMobile &&
              !error.secondName &&
              isTouched.secondName &&
              !isInternationalFlight &&
              oneOfFlightsIsCharter?.length === 0 && (
                <SubLabel style={{ fontSize: 12, color: '#737373' }}>
                  Мы переведем ФИО на латиницу{' '}
                </SubLabel>
              )}
          </FioBLock>
        )}

        <div className="field-4" style={{ position: 'relative' }}>
          <Checkbox
            onChange={handleCheckboxChange}
            type="squar"
            label="Без отчества"
            checked={
              !props.secondNameRequired ||
              (oneOfFlightsIsCharter?.length !== 0 && isInternationalFlight)
            }
          />
        </div>
        {isMobile && (
          <InputMask
            mask="99.99.9999"
            onChange={(e) => handleBirthDateChange(e.target.value)}
            value={
              moment(props.birthDate).isValid()
                ? moment(props.birthDate).format('DD.MM.YYYY')
                : props.birthDate?.toString()
            }
            // maskChar={'дд.мм.гггг'}
            alwaysShowMask={false}
          >
            <StyledField
              className="field-5"
              label="Дата рождения"
              name="partnerId"
              type="tel"
              error={
                error.birthDate &&
                isTouched.birthDate &&
                !error.birthDate.includes('must be a `date`')
                  ? error.birthDate
                  : error.birthDate &&
                      isTouched.birthDate &&
                      error.birthDate.includes('must be a `date`')
                    ? 'Неправильный формат'
                    : undefined
              }
              inputMode="numeric"
              placeholder="дд.мм.гггг"
            />
          </InputMask>
        )}
        {!isMobile && (
          <DatePicker
            dataCy="birthDateInput"
            className="field-5"
            value={props.birthDate}
            onChange={handleBirthDateChange}
            label="Дата рождения"
            error={
              error.birthDate && isTouched.birthDate
                ? error.birthDate
                : undefined
            }
            isBirthDate={true}
            selectClose={true}
          />
        )}

        <div
          className="field-6"
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Label>Пол</Label>
          <div>
            <RadioGroup
              name={`passenger-${index}`}
              flex
              defaultValue={'m'}
              onChange={handleTicketClassChange}
              items={[
                { value: 'm', label: 'Мужской' },
                { value: 'f', label: 'Женский' },
              ]}
              withAutoSelect={!!modalData}
              autoSelectData={modalData ? modalData.sex : ''}
            />
          </div>
        </div>

        <div
          className="field-7"
          data-cy={'citizenshipSelect'}
          style={{ marginTop: 5 }}
        >
          <Label>Гражданство </Label>

          <CountriesSelect
            searchType={'avia'}
            error={error.citizenship}
            citizenship={citizenship}
            handleCitizenshipChange={(val) => handleCitizenshipChange(val)}
            countriesList={countriesList}
          />
        </div>
        <div
          className="field-8"
          data-cy={'documentSelect'}
          style={{ marginTop: 5 }}
        >
          <Select
            label="Выберите документ"
            onChange={handleDocumentTypeChange}
            isSearchable={false}
            value={documentType}
            defaultValue={DocumentType[0]}
            options={DocumentType}
          />
        </div>
        {notSamo ? (
          <div className="field-9">
            <NumberTooltip
              handleChange={handleChangeDocumentNumber}
              handleBlur={handleBlur}
              mask={documentMask}
              value={props.number}
              isNumberFocus={(x: boolean) => setIsNumberFocus(x)}
            >
              <StyledField
                data-cy="passportNumber"
                wrapperClassName="custom-input"
                error={
                  error.number && isTouched.number ? error.number : undefined
                }
                label="Серия и номер"
                placeholder={numberPlaceholder}
                name="number"
                // isInfo={
                //   documentType?.value === 'BIRTH_CERTIFICATE' ? true : false
                // }
              />
            </NumberTooltip>
            {isNumberFocus && !isTouched.number && error.number && (
              <HandleErrorText>Невалидный номер документа</HandleErrorText>
            )}

            {documentType?.value === 'BIRTH_CERTIFICATE' && (
              <>
                <Label style={{ fontSize: 12, color: '#737373' }}>
                  Латинские буквы (Х, V, I), 2 буквы кириллицей и 6 цифр <br />
                </Label>
                <div
                  style={{
                    fontSize: 12,
                    color: '#737373',
                    paddingTop: 7,
                    fontStyle: 'italic',
                  }}
                >
                  <span style={{ color: '#4872F2' }}>Пример:</span> VI-МБ 563456
                </div>
              </>
            )}
          </div>
        ) : (
          <>
            <div className="field-9">
              <NumberTooltip
                handleChange={(event) => {
                  formik.setFieldValue(
                    `passengers.${index}.documentSeries`,
                    event.target.value,
                  );
                }}
                handleBlur={handleBlurDocumentSeries}
                value={props.documentSeries}
                mask={documentMaskCharterSeries}
              >
                <StyledField
                  placeholder={documentMaskCharterSeries}
                  wrapperClassName="custom-input"
                  label="Серия документа"
                  name="documentSeries"
                  error={
                    error.documentSeries && isTouched.documentSeries
                      ? error.documentSeries
                      : undefined
                  }
                  // isInfo={
                  //   documentType?.value === 'BIRTH_CERTIFICATE' ? true : false
                  // }
                />
              </NumberTooltip>
              {documentType?.value === 'BIRTH_CERTIFICATE' && (
                <Label style={{ fontSize: 12, color: '#737373' }}>
                  Латинские буквы (Х, V, I) и 2 буквы кириллицей
                </Label>
              )}
            </div>
            <div className="field-10">
              <NumberTooltip
                handleChange={(event) => {
                  formik.setFieldValue(
                    `passengers.${index}.documentInfo`,
                    event.target.value,
                  );
                }}
                handleBlur={handleBlurDocumentInfo}
                value={props.documentInfo}
                mask={documentMaskCharterNumber}
              >
                <StyledField
                  placeholder={documentMaskCharterNumber}
                  wrapperClassName="custom-input"
                  label="Номер документа"
                  name="documentInfo"
                  error={
                    error.documentInfo && isTouched.documentInfo
                      ? error.documentInfo
                      : undefined
                  }

                  // isInfo={
                  //   documentType?.value === 'BIRTH_CERTIFICATE' ? true : false
                  // }
                />
              </NumberTooltip>
              {documentType?.value === 'BIRTH_CERTIFICATE' && (
                <Label style={{ fontSize: 12, color: '#737373' }}>
                  6 цифр{' '}
                </Label>
              )}
            </div>
          </>
        )}

        {(documentType?.value === 'INTERNATIONAL_PASSPORT1' ||
          documentType?.value === 'INTERNATIONAL_PASSPORT' ||
          documentType?.value === 'NATIONALPASSPORT') && (
          <div className="field-12">
            <InputMask
              mask="99.99.9999"
              onChange={(e) => handledateOfIssue(e.target.value)}
              value={
                moment(props.dateOfIssue).isValid()
                  ? moment(props.dateOfIssue).format('DD.MM.YYYY')
                  : props.dateOfIssue?.toString()
              }
              maskChar={null}
              alwaysShowMask={false}
            >
              <StyledField
                placeholder="дд.мм.гггг"
                data-cy="expireDateOfDocument"
                label="Срок действия"
                name="partnerId"
                type="tel"
                error={
                  error.dateOfIssue &&
                  isTouched.dateOfIssue &&
                  !error.dateOfIssue.includes('must be a `date`')
                    ? error.dateOfIssue
                    : error.dateOfIssue &&
                        isTouched.dateOfIssue &&
                        error.dateOfIssue.includes('must be a `date`')
                      ? 'Неправильный формат'
                      : undefined
                }
                inputMode="numeric"
              />
            </InputMask>

            {/* {(documentType.value === 'INTERNATIONAL_PASSPORT1' ||
              documentType.value === 'INTERNATIONAL_PASSPORT') && (
              <div className="field-14">
                <Checkbox
                  onChange={handleDateOfIssueChange}
                  type="squar"
                  label="Без срока"
                  checked={!props.dateOfIssueRequired}
                />
              </div>
            )} */}
          </div>
        )}
      </FormBlockNew>
      {Object.values(airlinesCombined).length >
        formik.values.passengers[index].loyality.length && (
        <>
          <AddPassangers
            onClick={() => {
              handleLogging(
                'CLICK',
                LOGGING_ACTION_IDS.AVIA_BOOKING_FIRST_STEP
                  .PASSENGER_BONUS_CARD_ADD_BTN,
              );
              formik.setFieldValue(`passengers.${index}.loyality`, [
                ...formik.values.passengers[index].loyality,
                {
                  code: '',
                  value: '',
                },
              ]);
            }}
          >
            + Добавить бонусную карту
          </AddPassangers>
        </>
      )}

      {isAvia && formik.values.passengers[index].loyality.length !== 0 && (
        <>
          {formik.values.passengers[index].loyality.map((x, i) => (
            <LoyalityBlock key={index} checked={isLoyalVisibile}>
              <>
                <div className="field-13">
                  <Select
                    label="Авиакомпания"
                    onChange={(e) => handleAviacompanyChange(e, i)}
                    isSearchable={false}
                    placeholder=""
                    //@ts-ignore
                    options={Object.values(airlinesCombined)}
                  />
                </div>
                <CrossRow className="field-14">
                  <StyledField
                    wrapperClassName="custom-input"
                    label="№ бонусной карты"
                    name={`loyalityValue + ${i}`}
                    onChange={(e) => handleChangeLoyalInput(e, i)}
                    value={props.loyality[i]?.value}
                    type="number"
                  />

                  <CrossBlock
                    onClick={() => {
                      formik.setFieldValue(`passengers.${index}.loyality`, []);
                    }}
                  >
                    <Cross />
                  </CrossBlock>
                </CrossRow>
              </>
            </LoyalityBlock>
          ))}
        </>
      )}
      {/* <AncilllaryServices ids={props.ids} /> */}
      {isAuthorized && filteredPassengers.length > 0 && (
        <PassangerAutoSelectModal
          passangers={filteredPassengers}
          isOpen={isModalOpen}
          selectPassanger={setModalData}
          onClose={closeAutoSelect}
          setProgress={setProgress}
          autoSelectInProgress={autoSelectInProgress}
          oneOfFlightsIsCharter={oneOfFlightsIsCharter}
          setCitizenAbsolute={setCitizenAbsolute}
        />
      )}
    </Wrapper>
  );
};

export default PassengerItem;
